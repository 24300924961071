<template>
  <el-container class="p_container">
    <el-main>
      <div class="news_wrap">
        <div class="gome_news">
          <h1>
            让蓉城人民再“安逸”一点！
          </h1>
          <div class="gome_news_tip">
            <p>{{ data.tip }}</p>
          </div>
          <div class="gome_news_content">
            <img src="@/assets/img/news_img1.png" alt="" />
            <p>
              近年来，向来以“安逸”著称的成都，正以前所未有的速度快速发展，在国内经济发展浪潮中尽显锋芒。成都经济水平稳居西南地区首位，在新一线城市的综合较量中独占鳌头，成为各大企业、品牌眼里中的兵家必争之地。
            </p>
            <p>
              2021年8月23日，国美控股集团旗下国美家与成都交子金融控股集团有限公司旗下鼎立资产正式签署合作协议，正式开启“成都万和广场”迭代升级新里程。
            </p>
            <p>
              万和广场位于成都市一环内的核心商圈——八宝街，周边聚集金融商务区及青羊区政府机构、大型居住社区，人流密集、商办氛围浓厚、商业资源丰富，有效辐射人口约132万。
            </p>
            <p>
              此次国美家携手鼎立资产共同升级打造成都万和广场，国美家将为成都注入新兴的商业服务模式与理念，以
              “共享、共建、共赢“的信念，结合共享赋能、线上线下一体化运营等创新模式，打造全新的本地生活数字化平台—乐活秀。
            </p>
            <img src="@/assets/img/news_img2.png" alt="" />
            <p>
              乐活秀以“商品展示体验、家延伸服务、家娱乐社交”三大功能板块为核心，秉承“到店、到网、到家”理念，以技术+共享赋能整合线上线下优势资源打造的本地生活数字化平台，实现线上线下一套成本，为商户提供可持续发展的降本增效。
            </p>
            <p>
              乐活秀APP/小程序采用 “一城一页”
              、“一店一页”为商户建立线上门店，承接1-8公里家庭消费需求及服务功能，提供智能预约、智能推荐、智能停车&导航、视频导购、管家服务、线上支付、撮合服务等多项智能服务。乐活秀基于共享经营理念，利用分时共享、场地共享、设备共享、人员共享等共享模式，提升经营效率、资源及人员使用效率，进一步实现B端的有效协同和C端共享体验！此外，乐活秀还为B端商户打造线上撮合平台，通过抢单、派单和推送的形式，高效撮合具备承接力的雇主端商户与内容型服务商之间达成合作，满足两端商户对家庭客群的个性化需求。
            </p>
            <p>
              乐活秀线下商业门店以“商品展示体验、家延伸服务、家娱乐社交”三大功能板块为核心，不仅精准覆盖不同年龄段家庭高、中、低频生活消费需求，并通过线上选品、比价、支付，线下到店体验分享营造新鲜的功能体验场景，为消费者提供“服务+体验+质量+购物环境”兼优的高品质消费体验，满足家庭各年龄层的消费需求。从而建立家庭会员消费的圈层文化，提供一站式的新零售、新服务、全客群商业服务模式，为蓉城的家庭客群带来覆盖餐饮、娱乐、购物、休闲、教育等全方位的消费体验！
            </p>
            <p class="finallyP">
              让蓉城人民再“安逸”一点，敬请期待乐活秀进驻成都的精彩首秀吧！
            </p>
          </div>
          <div class="gome_news_footer">
            <div class="gome_news_footer_title">
              <h3>更多精彩内容</h3>
              <div></div>
            </div>
          </div>
        </div>
        <!-- 放在外层与浏览器同宽 -->
        <div class="gome_news_card">
          <div class="gome_news_n">
            <div class="right_card">
              <div class="gome_news-content">
                <!-- 加一层div遮罩隐藏掉滚动条 -->
                <div class="gome_overflow_n">
                  <div
                    class="gome_news-content-con"
                    v-for="item in items"
                    :key="item.idx"
                    @click="toNewsDetail(item.idx)"
                  >
                    <img :src="item.image" alt="" />

                    <div class="gome_news-content-con-title">
                      <h3 class="gome_news-content-con-title-name">
                        {{ item.name }}
                      </h3>
                      <p class="gome_news-content-con-title-des">
                        {{ item.des }}
                      </p>
                      <h2 class="gome_news-content-con-title-time">
                        {{ item.time }}
                      </h2>
                    </div>
                    <p class="gome_news-content-con-more">
                      阅读全部>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer>
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  // 注册子组件
  components: {
    Footer,
  },
  data() {
    return {
      items: [
        {
          idx: "1",
          image: require("@/assets/img/news_img1.png"),
          name: "让蓉城人民再“安逸”...",
          des:
            "近年来，向来以“安逸”著称的成都，正以前所未有的速度快速发展，在国内经济发展浪潮中...",
          date: "2021.08.23",
        },
        {
          idx: "2",
          image: require("@/assets/img/news_img3.png"),
          name: "重庆沙坪坝区副区长...",
          des:
            "2021年10月20日  重庆沙坪坝区副区长代建红、国际物流枢纽园区董事长罗书权一行拜会...",
          date: "2021.10.20",
        },
      ],
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        title: "成都市青羊区领导莅临国美集团",
        tip: "国美家动态",
      }),
    },
  },
  methods: {
    enter_news() {},
    toNewsDetail(idx) {
      this.$router.push(`/newsDetail${idx}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.p_container {
  width: 100%;
  background: #fff;
  overflow-x: auto;
  .el-footer {
    padding: 0;
    overflow-x: auto;
    width: 100%;
    height: auto !important;
    background: #1e222c;
  }

  .el-main {
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }
}

.news_wrap {
  width: 100%;
  overflow-x: auto;
  .gome_news {
    width: 1200px;
    margin: auto;
    position: relative;
    text-align: left;
    margin-top: 182px;
    h1 {
      font-size: 60px;
      font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: normal;
      color: #1f2329;
    }
    .gome_news_tip {
      width: 126px;
      height: 38px;
      margin-top: 40px;
      background: #feead2;
      border-radius: 4px;
      text-align: center;
      p {
        line-height: 38px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #f08107;
      }
    }
    .gome_news_content {
      margin-top: 60px;
      p {
        font-size: 28px;
        font-family: MicrosoftYaHei;
        color: #333333;
        // line-height: 50px;
        margin-top: 60px;
      }
      img {
        width: 1200px;
        height: 800px;
        margin-top: 60px;
      }
      .finallyP {
        margin-bottom: 0;
      }
    }
  }
  .gome_news_footer {
    .gome_news_footer_title {
      padding-top: 200px;
      padding-bottom: 118px;
      opacity: 1;
      h3 {
        font-size: 40px;
        font-family: Hiragino Sans GB;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        color: #1f2329;
        opacity: 1;
      }
      div {
        width: 60px;
        height: 8px;
        background: linear-gradient(to right, #f6ba4f, #ea8427);
        border-radius: 4px;
        opacity: 1;
        text-align: bottom;
        top: 10px;
        margin: auto;
        position: relative;
      }
    }
    /*
    .gome_news_card {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: auto;
      .gome_news-content-con {
        width: 380px;
        height: 523px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fafafa;
        border-radius: 10px;
        margin-bottom: 50px;
        img {
          width: 380px;
          height: 201px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
        .gome_news-content-con-title {
          display: block;
          position: absolute;
          bottom: 97px;
          left: 0;
          margin-top: 40px;
          margin-left: 30px;
          margin-right: 20px;
          box-sizing: border-box;

          .gome_news-content-con-title-name {
            width: 100%;
            opacity: 1;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #1f2329;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .gome_news-content-con-title-des {
            box-sizing: border-box;
            height: 74px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
            margin-top: 16px;
            margin-bottom: 16px;
          }
          .gome_news-content-con-title-time {
            height: 19px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 26px;
            color: #666666;
          }
        }
        .content-con-more {
          font-size: 18px;
          position: absolute;
          color: #eb8729;
          bottom: 26px;
          left: 30px;
        }
      }
    }*/
  }
}
.gome_news_n {
  width: 100%;
  margin: auto;
  position: relative;

  .right_card {
    padding-top: 0px;
    height: 620px;
    // 隐藏滚动条
    .gome_news-content {
      width: 100%;
      height: 535px;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      .gome_overflow_n {
        display: flex;
        padding: 0 40px;
        width: 100%;
        height: 700px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        justify-content: flex-start;
      }

      &-con {
        width: 380px;
        height: 523px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fafafa;
        border-radius: 10px;
        margin-right: 60px;
        &:hover {
          cursor: pointer;
          background-color: #fff;
          box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
        }
        img {
          width: 380px;
          height: 201px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
        &-more {
          font-size: 18px;
          position: absolute;
          color: #eb8729;
          bottom: 26px;
          left: 30px;
          cursor: pointer;
        }
        .gome_news-content-con-title {
          display: block;
          position: absolute;
          bottom: 97px;
          left: 0;
          margin-top: 40px;
          padding: 0 20px;
          box-sizing: border-box;
          &-name {
            width: 100%;
            opacity: 1;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #1f2329;
            white-space: nowrap; /*把文本强制显示在一行*/
            text-overflow: ellipsis; /*超出部分显示...*/
          }
          &-des {
            box-sizing: border-box;
            height: 74px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
            margin-top: 16px;
            margin-bottom: 16px;
          }
          &-time {
            height: 19px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 26px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
