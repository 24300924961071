<template>
	<div class="view-colum">
		<div v-show="true">
			<div>
				<p>
					乐活通平台（下称或称&ldquo;我们&rdquo;）非常尊重并保护个人信息和隐私安全，在您使用国内乐活通平台提供的服务时，我们将按照本隐私政策收集、使用及共享您的个人信息。本隐私政策包含了我们收集、存储、使用、共享和保护您的个人信息的条款，我们希望通过本隐私政策向您清晰地介绍我们对您个人信息的处理方式，因此我们建议您完整地阅读本隐私政策，以帮助您了解维护自己隐私权的方式。<strong
						>如果您不同意本隐私政策的任何内容，您应立即停止使用我们的服务。当您使用我们提供的任一服务时，即表示您已同意我们按照本隐私政策来合法使用和保护您的个人信息。</strong
					>
				</p>
				<p><strong>&nbsp;</strong></p>
				<p>
					<strong
						>乐活通平台收集、使用、共享和保护您的个人信息，是在遵守国家法律法规规定的前提下，出于向您提供乐活通平台的产品及服务并不断提升产品和服务质量的目的，包括但不限于支持我们开展乐活通平台产品及服务相关的市场活动、完善现有产品及服务功能、开发新产品或新服务。</strong
					>
				</p>
				<p><strong>如对本隐私政策或相关事宜有任何问题，您可随时与我们的在线客服联系。</strong></p>
				<p><strong>&nbsp;</strong></p>
				<p>本隐私政策目录如下：</p>
				<p>一、定义</p>
				<p>二、适用范围</p>
				<p>三、我们如何收集您的个人信息</p>
				<p>四、我们如何使用您的个人信息</p>
				<p>五、我们如何共享、转让、公开披露您的个人信息</p>
				<p>六、您个人信息的安全</p>
				<p>七、您个人信息的保存</p>
				<p>八、您如何管理您的个人信息</p>
				<p>九、您的信息如何在全球范围转移</p>
				<p>十、Cookie及网络Beacon的使用</p>
				<p>十一、未成年人的个人信息保护</p>
				<p>十二、通知和修订</p>
				<p>十三、如何联系我们</p>
				<p><strong>&nbsp;</strong></p>
				<p><strong>一、定义</strong></p>
				<p>
					&nbsp;&nbsp;&nbsp;
					1.乐活通平台：指国美家（海南）商业管理有限公司运营之&rdquo;乐活通&rdquo;移动应用软件（APP、小程序）等（包括未来技术发展出现的新的形态）。
				</p>
				<p>
					2.个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，本隐私政策涉及的个人信息包括：<strong
						>基本信息（包括个人姓名、性别、住址、个人电话号码）；个人身份信息（包括身份证、驾驶证、行驶证、特种作业操作证、护士证、健康证、导游证、开换锁备案证）；个人生物识别信息（如面部识别特征）；网络身份识别信息（包括系统账号及密码、</strong
					><strong>IP</strong
					><strong
						>地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（银行账号、交易和消费记录、优惠券等虚拟财产信息）；联系人信息（包括通讯录）；个人通信信息（包括通信记录和内容、短信）；个人上网记录（包括网站浏览记录、软件使用记录、点击记录）；个人常用设备信息（包括硬件型号、设备MAC</strong
					><strong>地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID</strong
					><strong>等、SIM</strong><strong>卡IMSI</strong
					><strong
						>信息等在内的描述个人常用设备基本情况的信息））；个人位置信息（包括行程信息、精准定位信息、经纬度等）。</strong
					>
				</p>
				<p>
					<strong>3.</strong
					><strong
						>个人敏感信息：指一旦泄漏、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策涉及的个人敏感信息包括：您的财产信息（银行账号、交易和消费记录、优惠券等虚拟财产信息）；个人身份信息（包括身份证、驾驶证、行驶证、特种作业操作证、护士证、健康证、导游证、开换锁备案证）；个人生物识别信息（如面部识别特征）；网络身份标识信息（包括系统账号及密码、邮箱地址及与前述有关的密码、口令、口令保护答案））；其他信息（包括通讯录、通信记录和内容、个人电话号码、手机号码、行程信息、网页浏览记录、精准定位信息）。</strong
					>
				</p>
				<p>4.除另有约定外，本政策内容中的相关定义与《乐活通平台服务协议》中保持一致。</p>
				<p><strong>二、适用范围</strong></p>
				<p>
					本隐私政策适用于乐活通平台任意版本服务，<strong
						>乐活通平台将按照本政策说明的场景使用或共享您的个人信息，如果超出本政策说明的范围，我们会再次向您告知并征得您的明示同意。</strong
					>
				</p>
				<p><strong>三、我们如何收集您的个人信息</strong></p>
				<p>
					我们收集信息是为了向您提供更好以及更个性化的服务，并努力提高您的用户体验。我们收集信息的种类如下：
				</p>
				<ul>
					<li>您须授权乐活通平台收集和使用您的个人信息的情形</li>
				</ul>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					我们可能会收集、保存和使用下列与您有关的信息以实现您相应的需求。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.在乐活通平台开展经营活动需提供的信息</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>（1</strong><strong>）注册（即注册乐活通平台账号）</strong>
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					您首先需要注册一个账户成为乐活通平台用户。您可以通过两种方式注册账号，第一种方式，您需提供您的<strong>手机号码，并设置乐活通平台的密码，您可通过输入账号密码或输入发送给您的短信验证码的方式</strong>来验证您的身份是否有效，如果您拒绝提供手机号码，乐活通平台将无法为您创建账号并提供服务。第二种方式，您可选择通过&ldquo;微信登录&rdquo;方式登录乐活通平台，如您选择这种方式，我们还需要获取您在<strong
						>微信平台的</strong
					><strong>OpenID</strong
					>。我们以此识别您在乐活通平台的网络身份，并为您提供平台服务，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。账号注册成功后，您的头像、名称、简介、公告、常驻城市、服务地址、服务范围均为系统默认信息，您可以自行补充前述信息。您补充提供的信息将有助于我们为您提供个性化的服务，其中，服务地址、服务范围为您在乐活通平台从事经营活动前必须提供/补充的信息，其他几项信息如您不补充，不会影响您使用乐活通平台从事经营服务的基本功能。
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<strong>（2</strong><strong>）身份核验程序</strong></p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;在乐活通平台开展经营活动前，您需就您将从事的经营活动事项向乐活通平台提供相关真实、合法、有效的资质，经过乐活通平台接入的第三方供应商或人工方式进行审核通过后，您方可在乐活通平台开展相关的经营活动。您可以选择在通过身份核验前，使用乐活通平台发布服务信息的功能，您添加的服务信息将存储在您的账户中，在您通过身份核验后可向用户发布。身份核验程序中，您需提供的信息可能包括您的<strong>身份信息（如身份证、驾驶证、行驶证、特种作业操作证、护士证、健康证、导游证、开换锁备案证）、个人财产信息（如交易和消费记录、银行账号）、个人生物识别信息（如面部识别特种）</strong>，向乐活通平台提供前述信息时，您需要授权我们使用您的摄像头（相机）、图片上传功能。
				</p>
				<p>
					<strong>设备信息：</strong
					>我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP
					地址、GPS位置以及能够提供相关信息的Wi-Fi 接入点、蓝牙和基站等传感器信息）。
				</p>
				<p>
					<strong>日志信息：</strong
					>当您使用乐活通平台时，我们会收集您的浏览、搜索记录等信息并作为有关网络日志进行保存，包括您的<strong
						>IP</strong
					><strong
						>地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、发布的信息、浏览、搜索、网络请求</strong
					>等。
				</p>
				<p>
					<strong>请您注意，单独的设备信息及日志信息无法识别您的身份，不属于您的个人信息</strong
					>，我们有权对其进行使用；如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。为向您提供更便捷、更符合您个性化需求的信息展示、搜索及推送服务，我们会根据您的日志信息集合其他信息，提取您的偏好特征，并基于特征标签进行间接人群画像，展示、推送信息和可能的商业广告。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订。
				</p>
				<p>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;（3）发布服务/需求及接单程序</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;您在通过身份核验程序后，可自行发布相应的服务信息，发布服务/需求的内容可以包括<strong>标题、宣传图片、服务描述、服务收费、接单类型、服务范围</strong>等内容；发布宣传图片时，您<strong>需授权我们使用您的摄像头（相机）、图片上传功能</strong>。对于您发布的服务信息，乐活通平台依据相关法律法规规定禁止发布的、违反公序良俗的、可能侵犯第三方合法权益的信息，乐活通平台有权不予审核通过。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;在您使用乐活通平台发布服务及实际为用户提供服务时，乐活通平台将获取您的位置信息，以判断您可否从事经营活动及用户提供服务的情况；您可以自主选择是否授权乐活通平台开启移动设备的定位功能，如您同意授权开启定位功能，乐活通平台会收集和使用您的位置信息以便您使用乐活通平台的服务。我们会使用有关技术获取您的位置信息（准确度会有所不同），这些技术包括IP地址、GPS以及能够提供相关信息的WLAN（如Wi-Fi）接入点、蓝牙和基站等。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;如您发布的服务内容通过乐活通平台审核，用户可在乐活通平台浏览到您发布的服务信息，并向您预约下单、通过乐活通平台与您沟通服务的内容。您使用乐活通平台的过程中，如通过乐活通平台接打电话或乐活通平台IM功能进行沟通时，我们会收集您的通讯内容、通话时长、主被叫手机号码信息，以及您通过乐活通平台发送的文字信息内容。因技术条件有限，乐活通平台可能在不同城市逐步上线该项功能。请您理解并同意：1）如您与用户产生任何服务纠纷，乐活通平台可调取并使用通讯信息作为解决相关纠纷的参考依据；2）您无法自行下载、调取通讯信息，该信息由乐活通平台服务器保存，除有权机关依法调取并出具相关法律文件依法调取外，乐活通平台不会将通讯信息提供给任何自然人或机构（包括当事人）。
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; （4）争议处理</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;当您与我们的客服联系或提出争议处理申请时，为了保障您的账号及系统安全，我们需要您提供必要的个人信息以核验您的身份。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息、订单信息、联系方式以及您为了证明相关事实提供的其他信息），如果您针对具体订单进行咨询、投诉或提供建议时的，我们会使用您的账号信息和订单信息。
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; （5）为您提供安全保障</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;为了保障您的账号安全、交易安全以及系统运行安全，在您使用乐活通平台服务过程中，我们可能会收集您使用乐活通平台的设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、浏览器类型，以便您更便捷的使用乐活通平台产品。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会使用或整合您的个人信息以及我们的关联方、合作伙伴取得您授权或依法共享的信息，根据您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，包括验证身份，预防、发现、调查可能存在的欺诈、网络病毒、网络攻击等安全风险以及违反我们或关联方协议、政策或规则等行为，以保护您、其他用户、我们或关联方的合法权益，并记录一些我们认为有风险的链接（&ldquo;URL&rdquo;）。
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; （6）产品业务功能所收集使用您的信息逐项列举</p>
				<table>
					<tbody>
						<tr>
							<td width="102">
								<p>业务场景</p>
							</td>
							<td width="227">
								<p>个人信息类型</p>
							</td>
							<td width="240">
								<p>个人信息字段</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>信息管理</p>
							</td>
							<td width="227">
								<p>个人基本资料</p>
							</td>
							<td width="240">
								<p>个人手机号码、地址</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>我的认证</p>
							</td>
							<td width="227">
								<p>个人基本资料、个人身份信息、个人生物识别信息</p>
							</td>
							<td width="240">
								<p>
									姓名、身份证、资质证书（如驾驶证、行驶证、特种作业操作证、护士证、健康证、导游证、开换锁备案证）、面部识别特征
								</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>设置</p>
							</td>
							<td width="227">
								<p>网络身份标识信息</p>
							</td>
							<td width="240">
								<p>系统账号密码</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>订单</p>
							</td>
							<td width="227">
								<p>个人上网记录、个人位置信息、个人通信信息</p>
							</td>
							<td width="240">
								<p>接单记录、地址定位、通话记录</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>支付设置</p>
							</td>
							<td width="227">
								<p>个人基本资料、个人身份信息、个人财产信息</p>
							</td>
							<td width="240">
								<p>个人姓名、生日、身份证、银行账号</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>搜索</p>
							</td>
							<td width="227">
								<p>个人上网记录</p>
							</td>
							<td width="240">
								<p>订单记录</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>会员中心</p>
							</td>
							<td width="227">
								<p>个人资本资料</p>
							</td>
							<td width="240">
								<p>个人姓名、性别、出生日期、个人手机号码、地址</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>我的消息</p>
							</td>
							<td width="227">
								<p>个人通信信息</p>
							</td>
							<td width="240">
								<p>通信记录和内容</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>推送</p>
							</td>
							<td width="227">
								<p>个人基本信息、个人上网记录</p>
							</td>
							<td width="240">
								<p>地址、订单信息</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>评价</p>
							</td>
							<td width="227">
								<p>个人基本资料</p>
							</td>
							<td width="240">
								<p>个人昵称、头像</p>
							</td>
						</tr>
						<tr>
							<td width="102">
								<p>登录</p>
							</td>
							<td width="227">
								<p>个人基本资料</p>
							</td>
							<td width="240">
								<p>个人手机号码</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.改进我们的产品与/服务所必须的功能</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					我们可能会收集您的订单信息、浏览信息进行数据分析以形成用户画像，用来将您感兴趣的服务信息展示给您；或在您搜索时向您展示您可能希望找到的服务。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; （二）您可选择是否授权我们收集和使用您的个人信息的情形</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					1.为向您提供个性化的服务，您可以选择授权乐活通平台获取您的以下信息以使用我们提供的附加功能，我们会在符合法律规定并根据您具体授权的情况下收集并使用如下信息。这类信息将在您选择的具体功能和业务场景中进行收集，如果您不提供这些信息，不会影响您使用乐活通平台的基本功能。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（1）基于摄像头（相机）的附加功能：您可以使用这个附加功能完成拍照、人脸识别、扫码识别的功能。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（2）基于图片上传的附加功能：您可以在乐活通平台提交身份信息的图片以便我们识别您的身份，您也可以发布服务图片、回复用户评价时使用上传照片功能。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（3）基于通讯录信息的附加功能：我们将收集您的通讯录信息以方便您与其他用户沟通服务订单。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（4）基于日历信息的附加功能：您可以开启日历权限，使用添加日历等功能记录乐活通平台相关服务的日程，我们会收集上述信息用于向您展示或定时提醒。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（5）基于麦克风的语音技术相关附加功能：您可以开启麦克风权限后使用麦克风帮助您与其他用户实现语音交互，在这些功能中我们会收集您的录音内容以响应您的客服及争议处理等需求。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					2.上述附加功能可能需要您在您的设备中向我们开启您的摄像头（相机）、相册、通讯录、日历、麦克风以及其他设备访问权限，以实现这些功能所涉及的信息的收集和使用。您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					3.实现相关业务功能可能开启您的设备访问权限的逐项列举：
				</p>
				<table>
					<tbody>
						<tr>
							<td width="92">
								<p>业务场景</p>
							</td>
							<td width="217">
								<p>场景说明</p>
							</td>
							<td width="259">
								<p>访问及使用权限</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>扫一扫</p>
							</td>
							<td width="217">
								<p>扫码时调用手机摄像头扫描条码/二维码或者从相册里选择图片</p>
							</td>
							<td width="259">
								<p>拍摄照片和录制视频</p>
								<p>读取存储卡中的内容</p>
								<p>修改或删除储存卡中内容</p>
								<p>安装卸载文件系统</p>
								<p>保持唤醒</p>
								<p>防止手机休眠</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>分享</p>
							</td>
							<td width="217">
								<p>分享图片到朋友圈时，需要从相册选择图片或者保存图片到本地</p>
							</td>
							<td width="259">
								<p>读取存储卡中的内容</p>
								<p>修改或删除储存卡中内容</p>
								<p>安装卸载文件系统</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>评价</p>
							</td>
							<td width="217">
								<p>发表、修改、追加评价时，添加相册照片或者拍照进行图片上传</p>
							</td>
							<td width="259">
								<p>拍摄照片和录制视频</p>
								<p>读取存储卡中内容</p>
								<p>修改或删除储存卡中内容</p>
								<p>安装卸载文件系统</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>数据统计</p>
							</td>
							<td width="217">
								<p>获取设备信息已给统计数据生成唯一标识</p>
							</td>
							<td width="259">
								<p>访问电话状态，包含设备的电话好吗，当前网络信息，呼叫信息</p>
								<p>获取系统版本</p>
								<p>获取设备幸好</p>
								<p>获取设备品牌</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>预约</p>
							</td>
							<td width="217">
								<p>在部分特定的预约场景中，通过读取和写进日历提醒用户</p>
							</td>
							<td width="259">
								<p>读取及写入用户日历权限</p>
							</td>
						</tr>
						<tr>
							<td rowspan="3" width="92">
								<p>在线IM</p>
							</td>
							<td width="217">
								<p>聊天时调用收集摄像头扫描条码/二维码或者从相册里选择图片</p>
							</td>
							<td width="259">
								<p>拍摄照片和录制视频</p>
								<p>读取存储卡中内容</p>
								<p>修改或删除储存卡中内容</p>
								<p>安装卸载文件系统</p>
							</td>
						</tr>
						<tr>
							<td width="217">
								<p>发起语音对话、播放语音对话</p>
							</td>
							<td width="259">
								<p>访问麦克风权限</p>
								<p>更改您的音频设置</p>
							</td>
						</tr>
						<tr>
							<td width="217">
								<p>消息提醒</p>
							</td>
							<td width="259">
								<p>控制震动</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>个人信息修改</p>
							</td>
							<td width="217">
								<p>修改头像时调用手机摄像头扫描条码/二维码或者从相册里选择图片</p>
							</td>
							<td width="259">
								<p>使用照相设备权限</p>
								<p>访问外部存储权限</p>
								<p>写外部存储权限</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>地址定位</p>
							</td>
							<td width="217">
								<p>访问首页时获取城市定位、下单时获取上门地址信息</p>
							</td>
							<td width="259">
								<p>访问定位权限</p>
								<p>获取额外的位置信息提供程序命令</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>个性化通知</p>
							</td>
							<td width="217">
								<p>订单服务状态发生变更、推荐服务或优惠信息</p>
							</td>
							<td width="259">
								<p>系统通知权限</p>
								<p>开机启动</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>电话</p>
							</td>
							<td width="217">
								<p>联系用户、联系客服时</p>
							</td>
							<td width="259">
								<p>拨打电话</p>
								<p>读取设备通话状态和识别码</p>
								<p>使用呼叫转移</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>通讯录</p>
							</td>
							<td width="217">
								<p>绑定通讯录好友列表</p>
							</td>
							<td width="259">
								<p>读取联系人</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>风控</p>
							</td>
							<td width="217">
								<p>保护信息安全、防止恶意攻击</p>
							</td>
							<td width="259">
								<p>获取imei信息</p>
								<p>获取mac地址</p>
								<p>获取屏幕分辨率</p>
								<p>获取屏幕亮度</p>
								<p>获取CPU架构</p>
								<p>获取手机内存</p>
								<p>获取手机可用内存</p>
								<p>获取运营商信息</p>
								<p>获取本地IP</p>
								<p>获取电池电量</p>
								<p>获取电池状态</p>
								<p>获取系统版本信息</p>
								<p>获取设备型号信息</p>
								<p>获取设备品牌信息</p>
								<p>获取IDFA</p>
								<p>获取IDFV</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>短信登录</p>
							</td>
							<td width="217">
								<p>用户登录时使用短信验证码方式登录</p>
							</td>
							<td width="259">
								<p>读取短信内容</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>缓存</p>
							</td>
							<td width="217">
								<p>浏览时，本机缓存数据，提升浏览体验</p>
							</td>
							<td width="259">
								<p>读取SD卡</p>
								<p>写SD卡</p>
							</td>
						</tr>
						<tr>
							<td width="92">
								<p>App通用功能</p>
							</td>
							<td width="217">
								<p>访问网络</p>
								<p>判断App前后胎</p>
								<p>App内应用升级</p>
								<p>获取手机异常日志</p>
								<p>获取蓝牙mac地址</p>
								<p>保持前台运行</p>
								<p>读取Logcat日志</p>
								<p>跳转到应用设置页面</p>
								<p>语言、字体设置</p>
							</td>
							<td width="259">
								<p>悬浮窗</p>
								<p>应用内安装其他应用</p>
								<p>查看WLAN状态</p>
								<p>查看网络连接</p>
								<p>更改WLAN状态</p>
								<p>更改网络连接性</p>
								<p>检索正在运行的应用</p>
								<p>完全的网络访问权限</p>
								<p>与蓝牙设备配对</p>
								<p>运行前台服务</p>
								<p>读取日志</p>
								<p>修改系统设置</p>
								<p>修改配置</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; （三）我们可能间接收集的个人信息</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					我们可能从第三方获取您授权共享的信息，我们承诺，在从第三方间接获取您的个人信息时，要求第三方说明个人信息来源，并会对个人信息来源的合法性进行确认；我们将了解并审慎注意，第三方已获得您的个人信息处理的授权同意范围，包括使用目的，您是否授权同意换让、共享、公开披露等，如第三方有违反行为的，我们会使用要求对方承担相应法律责任；同时，我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。我们开展业务所需进行的个人信息处理活动超出该授权同意范围的，将在获取您的个人信息后的合理期限内或处理您的个人信息前，征得您的明示同意。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（四）您充分知晓，以下情形中，我们收集使用个人信息无需征得您的授权同意：
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.与国家安全、国防安全直接相关的；</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					4.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 5.所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 7.根据个人信息主体要求签订和履行合同所必须的；</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					8.用于维护所提供的产品或服务的安全稳定运行所必须的，例如发现、处置产品或服务的故障；
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 9.法律法规规定的其他情形。</p>
				<p>四、我们如何使用您的个人信息</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					1.为向您提供服务、提升我们的服务质量以及优化您的服务体验，您理解并授权我们在符合法律规定的前提下，主要用于下列用途：
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; （1）向您提供您使用的各项服务，并维护、改进这些服务。</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（2）向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或在征得您同意的情况下与我们的合作伙伴共享信息以便它们向您发送有关其产品和服务的信息。如您不希望接受上述信息，可通过相应的退订功能进行退订。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（3）为提高您使用乐活通平台及乐活通平台关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别您的账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防网络漏洞、计算机病毒、网络攻击等安全风险，我们可能自行或通过第三方机构使用或整合您的交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依法律法规共享的信息，综合判断您的账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（4）我们可能会将来自某项服务的个人信息、交易信息与来自其他服务所获得的信息结合起来，进行综合统计、分析或加工等处理，以便为您提供更加个性化的服务，例如为了销售、奖励而使用、共享或披露您的信息。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					（5）我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					2.当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					3.当我们要将您的个人信息用于本政策未载明的其他用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					4.如我们停止运营乐活通平台产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并在满足法律法规规定的最短保存期间，对收集的个人信息进行删除或匿名化处理。如乐活通平台需将您的信息用于本政策未载明的其他用途，或者将基于特定目的的收集而来的信息用于其他目的时，会事先征求您的同意。
				</p>
				<p>五、我们如何共享、转让、公开披露您的个人信息</p>
				<p>（一）个人信息的共享</p>
				<p>我们将遵守相关法律法规，对您的个人信息予以保密除以下情况外，我们不会向其他人共享您的个人信息：</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.事先获得您明确的同意或授权。</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					2.基于法定的无需取得您同意的情形：根据法律法规的规定、诉讼争议解决需要，或行政、司法等有权机关依法提出的要求。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					3.为了提升我们的产品及服务质量或向您提供全新的产品及服务，我们会与我们的关联方共享您的相关信息。但我们只会共享必要的个人信息，且受隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					4.我们可能会向技术服务的供应商或用户共享您的个人信息，可能包括订单信息、联络信息、支付信息、地址信息、位置信息、设备信息，以保障服务订单的顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 5.我们向合作伙伴及第三方共享您的个人信息的情形逐项列举：</p>
				<table>
					<tbody>
						<tr>
							<td width="114">
								<p>是否潜入第三方代码、插件传输个人信息</p>
							</td>
							<td width="114">
								<p>场景描述</p>
							</td>
							<td width="114">
								<p>个人信息类型</p>
							</td>
							<td width="114">
								<p>个人信息字段</p>
							</td>
							<td width="114">
								<p>第三方机构名称</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>bugly</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>设备信息</p>
							</td>
							<td width="114">
								<p>腾讯bugly[https://bugly.qq.com/v2/]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>微信分享</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>设备信息</p>
							</td>
							<td width="114">
								<p>深圳市腾讯计算机系统有限公司[https://open.weixin.qq.com/]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>中国移动</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>设备信息</p>
							</td>
							<td width="114">
								<p>中国移动通信集团有限公司[http://www.10086.cn]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>中国电信</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>设备信息</p>
							</td>
							<td width="114">
								<p>中国电信集团有限公司[http://www.chinatelecom.com.cn/]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>微信支付</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>设备信息</p>
							</td>
							<td width="114">
								<p>深圳市腾讯计算机系统有限公司[https://open.weixin.qq.com/]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>支付宝支付</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>设备信息</p>
							</td>
							<td width="114">
								<p>支付宝（杭州）信息技术有限公司[http://docs.open.alipay.com]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>高德地图sdk</p>
							</td>
							<td width="114">
								<p>设备定位信息</p>
							</td>
							<td width="114">
								<p>设备信息</p>
								<p>位置权限</p>
							</td>
							<td width="114">
								<p>高德软件有限公司</p>
								<p>[https://lbs.amap.com]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>推送SDK</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>设备信息</p>
								<p>推送权限</p>
							</td>
							<td width="114">
								<p>华为：[http://developer.huawei.com/consumer]</p>
								<p>小米：[http://developer.xiaomi.com]</p>
								<p>OPPO：[https://open.oppomobile.com/]</p>
								<p>Vivo：[https://id.vivo.com.cn/#!/access/login]</p>
								<p>个推：[https://dev.getui.com/]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>腾讯人脸识别</p>
							</td>
							<td width="114">
								<p>用户ID、身份证姓名、订单信息</p>
							</td>
							<td width="114">
								<p>用户ID、身份证姓名、订单信息</p>
							</td>
							<td width="114">
								<p>深圳市腾讯计算机系统有限公司</p>
								<p>[https://cloud.tencent.com/]</p>
							</td>
						</tr>
						<tr>
							<td width="114">
								<p>是</p>
							</td>
							<td width="114">
								<p>百度语音引擎</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>个人常用设备信息</p>
							</td>
							<td width="114">
								<p>百度[https://ai.baidu.com/]</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p>
					6.为维护您的合法权益，在协助处理与您有关的交易纠纷或争议时，我们可能向您的交易相对方或存在利害关系的第三方提供解决交易纠纷或争议所必须的信息。
				</p>
				<p>
					7.我们已知晓对外共享、转让、公开披露个人信息所承担的相应法律责任。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					8.我们与第三方进行联合营销活动，我们可能与其共享活动过程中产生的、为完成活动所必要的个人信息，如参加活动的用户数、中奖名单、中奖人联系方式等，以便第三方能及时向您发放奖品。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					9.根据法律规定及合理商业习惯，在我们计划与其他公司合并或被其收购或进行其他资本市场活动（包括但不限于IPO，债券发行）时，以及其他情形下我们需要接受来自其他主体的尽职调查时，我们会把您的信息提供给必要的主体，但我们会通过和这些主体签署保密协议等方式要求其对您的个人信息采取合理的保密措施。
				</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 10.为维护我们及关联方或其他我们用户的合法权益。</p>
				<p>（二）个人信息的转让</p>
				<p>
					&nbsp;&nbsp;
					&nbsp;我们不会将您的个人信息转让给除乐活通平台以外的任何公司、组织和个人，但以下情况除外：
				</p>
				<p>1.事先获得您明确的同意或授权；</p>
				<p>2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
				<p>3.符合与您签署的相关协议或其他的法律文件约定所提供；</p>
				<p>
					4.在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
				</p>
				<p>&nbsp;</p>
				<p>&nbsp;&nbsp;&nbsp; （三）个人信息的公开披露</p>
				<p>&nbsp;&nbsp;&nbsp; 我们仅会在以下情况下，公开披露您的个人信息：</p>
				<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1.获得您明确同意后；</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					2.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
				</p>
				<p>六、您个人信息的安全</p>
				<p>
					1.为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，使您的个人信息不会被泄露、毁损或者丢失。我们会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力确保您的信息不被泄漏、毁损或丢失。我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取以下措施，保护您的个人信息：
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;
					（1）在您的浏览器与&ldquo;服务&rdquo;之间交换数据（如信用卡信息）时受SSL加密保护；
				</p>
				<p>&nbsp;&nbsp;&nbsp; （2）对网站提供https安全浏览方式；</p>
				<p>&nbsp;&nbsp;&nbsp; （3）我们会使用加密技术确保数据的保密性；</p>
				<p>&nbsp;&nbsp;&nbsp; （4）我们会使用受信赖的保护机制防止数据遭到恶意攻击；</p>
				<p>&nbsp;&nbsp;&nbsp; （5）我们会部署访问控制机制，确保只有授权人员才可访问个人信息；</p>
				<p>&nbsp;&nbsp;&nbsp; （6）我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
				<p>
					&nbsp;&nbsp;&nbsp;
					2.请使用复杂密码，协助乐活通平台保证您的账号安全。乐活通平台将尽力保障您发送给我们的个人信息的安全性。如因乐活通平台故意或重大过失致使您的个人信息被非授权访问、公开披露、篡改或毁坏，损害您的合法权益，乐活通平台将承担相应的法律责任。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;
					3.为应对个人信息泄漏、毁损、丢失等可能出现的安全风险，乐活通平台已经在公司范围内颁发多项制度，明确了安全事故、安全漏洞的分类分级标准，以及针对上述安全事故和漏洞，内部处理流程（包括应急响应流程）和补救措施。一旦发生安全事件，我们将及时向您告知事件基本情况和风险、我们已经采取或将要采取的措施、您如何自行降低风险的建议等。我们将及时以推送通知、信函或电话等方式将安全事件情况通知受影响的用户，当难以逐一告知用户时，我们将通过发布平台公告的方式发布警示信息。
				</p>
				<p>七、您个人信息的保存</p>
				<p>
					1.您的个人信息将存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
				</p>
				<p>
					2.在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：
				</p>
				<p>（1）完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；</p>
				<p>（2）保证我们为您提供服务的安全和质量；</p>
				<p>（3）您是否同意更长的留存期间；</p>
				<p>（4）是否存在保留期限的其他特别约定。</p>
				<p>在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。</p>
				<p>
					3.请您注意，当您成功注销乐活通平台账户后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。
				</p>
				<p>
					4.如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
				</p>
				<p><strong>八、您如何管理您的个人信息</strong></p>
				<p>
					按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
				</p>
				<p>（一）访问、更正您的个人信息</p>
				<p>
					1.店铺信息：如果您希望访问或编辑您的账户中的店铺信息、营业状态、店铺公告、联系方式、可服务时间、常驻城市、服务地址、服务范围信息，您可以在乐活通平台的&ldquo;我的&rdquo;-&ldquo;店铺管理&rdquo;中进行查询、访问、更正。
				</p>
				<p>
					2.认证信息：您可在乐活通平台的&ldquo;我的&rdquo;-&ldquo;店铺管理&rdquo;-&ldquo;我的认证&rdquo;中访问、修改您的认证信息数据。
				</p>
				<p>
					3.账号信息：您可在乐活通平台的&ldquo;我的&rdquo;-&ldquo;设置&rdquo;中查询、更正您的账号密码或将微信号与您的账号绑定。
				</p>
				<p>4.订单信息：您可以在乐活通平台的&ldquo;订单&rdquo;中查询、删除您的历史交易记录及交易状态等。</p>
				<p>
					5.如果您无法通过上述链接访问这些个人信息或您发现我们处理的关于您的个人信息有错误时，您可以通过乐活通平台在线客服与乐活通平台的客服团队取得联系，我们客服团队可能需要验证您的身份，并在验证您的身份后15日内做出答复或合理解释。
				</p>
				<p>（二）删除您的个人信息</p>
				<p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
				<p>1.如果我们处理个人信息的行为违反法律法规；</p>
				<p>2.如果我们收集、使用您的个人信息，却未征得您的同意；</p>
				<p>3.如果我们处理个人信息的行为违反了与您的约定；</p>
				<p>4.如果您不再使用我们的产品或服务，或您注销了账号；</p>
				<p>5.如果我们不再为您提供产品或服务。</p>
				<p>
					若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
				</p>
				<p>
					当您从我们的服务中删除信息后，我们可能不会立即备份系统中的删除相应的信息，但会在备份更新时删除这些信息。
				</p>
				<p>（三）注销您的账号</p>
				<p>
					您有权注销您的乐活通平台账户，您可以在乐活通平台&ldquo;我的&rdquo;-&ldquo;设置&rdquo;-&ldquo;注销账号&rdquo;中自行操作注销，也可以通过联系在线客服进行账号注销。您注销成功后，我们将根据法律法规的要求尽快删除您的个人信息。
				</p>
				<p>（四）改变您同意的范围及授权</p>
				<p>
					您可以通过在乐活通平台中删除信息、更改隐私设置或者在系统中关闭设备权限功能等方式改变同意范围或撤回您的授权。请您理解，当您执行上述操作后，我们将无法继续为您提供撤回同意或授权所对应的服务，但不会影响此前基于您的授权已经开展的个人信息处理。
				</p>
				<p>（五）您共享的信息</p>
				<p>
					您可以基于乐活通平台的服务与他人、社交网络分享您的个人信息，并自主选择分享的对象。当您与他人、社交网络或第三方服务提供商共享您的个人信息，或结合第三方的服务使用、或使用第三方的服务链接时，您可能会被披露或使第三方获取您的个人信息。我们建议您查看此类社交网络或第三方服务提供商的隐私声明，以了解它们如何处理您的信息。对于您使用第三方服务时向其提供与他人分享个人信息而产生的后果由您自行承担，并由相应责任方在法律规定范围内承担责任。
				</p>
				<p>
					在使用乐活通平台服务进行交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄漏，请您立即联络乐活通平台，以便乐活通平台采取相应措施。
				</p>
				<p>
					请注意，您在使用我们服务时自愿共享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您的交易信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
				</p>
				<p>（六）相应您的上述请求</p>
				<p>
					&nbsp;&nbsp;&nbsp;
					为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15日内做出答复。
				</p>
				<p>&nbsp;&nbsp;&nbsp; 在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
				<p>&nbsp;&nbsp;&nbsp; 1.与国家安全、国防安全直接相关的；</p>
				<p>&nbsp;&nbsp;&nbsp; 2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
				<p>&nbsp;&nbsp;&nbsp; 3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
				<p>&nbsp;&nbsp;&nbsp; 4.有充分证据表明您存在主管恶意或滥用权利的；</p>
				<p>&nbsp;&nbsp;&nbsp; 5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
				<p>&nbsp;&nbsp;&nbsp; 6.涉及商业秘密的。</p>
				<p>&nbsp;</p>
				<ul>
					<li><strong>您的信息如何在全球范围转移</strong></li>
				</ul>
				<p>
					<strong>我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：</strong>
				</p>
				<p><strong>1.</strong><strong>适用的法律有明确规定；</strong></p>
				<p><strong>2.</strong><strong>获得您的明确授权；</strong></p>
				<p><strong>3.</strong><strong>您通过互联网进行跨境交易等个人主动行为。</strong></p>
				<p>
					<strong
						>针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
					</strong>
				</p>
				<p>&nbsp;</p>
				<p>十、Cookie及网络Beacon的使用</p>
				<p>（一）Cookie的使用</p>
				<p>
					Cookie是由网页服务器存放在您的访问设备商的文本文件。指定给您的Cookie是唯一的，它只能被将Cookie发布给您的域中的Web服务器读取。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;
					我们使用Cookie来帮助您实现您的联机体验的个性化，使您在我们及其关联方获得更轻松的访问体验。例如，Cookie会帮助您在后续访问乐活通平台网站时调用您的信息，简化记录您填写个人信息（例如一键登录等）的流程；帮助您优化对广告的选择与互动；保护您的数据安全等。
				</p>
				<p>
					&nbsp;&nbsp;&nbsp;
					您有权接受或拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie。如果选择拒绝Cookie，那么您可能无法完全体验所访问的乐活通平台或某些服务的全部功能。
				</p>
				<p>（二）网络Beacon的使用</p>
				<p>
					&nbsp;&nbsp;&nbsp;
					我们网页上常会包含一些电子图像（称为：单像素&ldquo;GIF文件&rdquo;或&ldquo;网络Beacon&rdquo;），它们可以帮助网站计算浏览网页的用户或访问某些cookie。我们使用网络beacon的方式有：
				</p>
				<p>1.我们通过在我们网站上使用网络beacon，计算用户访问数量，并通过访问cookie辨认注册用户。</p>
				<p>&nbsp;&nbsp;&nbsp; 2.我们通过得到的cookie信息，可以在我们网站提供个性化服务。</p>
				<p>十一、未成年人的个人信息保护</p>
				<p>
					1.如果没有父母或监护人的同意，未成年人不得创建自己的会员账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用真快乐平台服务或向我们提供信息。
				</p>
				<p>
					2.对于经父母或监护人同意使用乐活通平台服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
				</p>
				<p>3.对于可能涉及的未成年人个人信息，我们进一步采取以下措施予以保障：</p>
				<p>
					（1）对于收集到的未成年人的个人信息，我们除遵守本隐私政策关于用户个人信息保护的约定外，还会切实按照《儿童个人信息网络保护规定》的要求，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则。
				</p>
				<p>
					（2）我们严格遵循《网络安全法》、《中华人民共和国未成年人保护法》、《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露收集到的儿童个人信息，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。
				</p>
				<p>
					（3）我们设立的个人信息保护相关负责人，凡涉及儿童个人信息保护相关事宜我们将会有专人负责对接。我们还会制定儿童个人信息保护的内部专门制度。
				</p>
				<p>
					（4）当您作为监护人为被监护的儿童选择乐活通平台相关服务时，我们可能需要向您收集被监护儿童的个人信息，用于向您履行相关服务之必要。在具体的服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用晒单、评价等功能时，可能会主动向我们提供儿童的个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的产品或服务，您应当正确引导并予以监护。
				</p>
				<p>
					（5）儿童或者监护人有权访问、更正、删除儿童个人信息。如您对儿童个人信息相关事宜有任何意见、建议和诉求，请联系我们。我们会随时为您提供服务。
				</p>
				<p>十二、通知和修订</p>
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;
					为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整。我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修改后的本政策并受之约束。
				</p>
				<p>十三、如何联系我们</p>
				<p>
					1.如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请通过访问在线客服系统与我们联系。
				</p>
				<p>
					2.一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及市场监管等监管部门进行投诉或举报，或通过向本协议签订地北京市朝阳区有管辖权的法院提起诉讼来寻求解决方案。
				</p>
				<p>3.我们的联系地址: 北京市朝阳区霄云路26号</p>
			</div>
		</div>

		<div v-show="false">
			<div>
				<p>
					<strong>【审慎阅读】</strong><br />
					本协议由国美家（海南）商业管理有限公司及其关联公司（以下简称&ldquo;乐活通&rdquo;）与乐活通平台用户（以下或称&ldquo;您&rdquo;）就乐活通平台服务（或称&ldquo;本服务&rdquo;）的相关事项所订立的有效合约。您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。<strong>请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体标识，您应重点阅读。</strong>当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与乐活通平台达成一致，成为乐活通平台用户。<strong>阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</strong>如您对本协议的条款有疑问，请通过客服渠道进行询问，我们将向您解释条款内容。
				</p>
				<p>
					<strong
						>如您为无民事行为能力人或限制民事行为能力人，请在监护人的指导下阅读本协议，并在征求您监护人同意的情况下使用本服务。</strong
					>
				</p>
				<p><strong>&nbsp;</strong></p>
				<ul>
					<li><strong>名词解释</strong></li>
				</ul>
				<p>1.1乐活通平台：指乐活通运营的乐活通APP及小程序等（包含未来技术发展出现的新的形态）。</p>
				<p>
					1.2乐活通平台服务：指乐活通通过乐活通平台向您提供的互联网信息发布及与互联网信息发布相关的技术服务等。
				</p>
				<p>
					1.3服务者：指利用自己的时间和技能，自愿为雇主提供服务，并在乐活通平台发布其所能提供的服务内容及价格等信息的用户。个人用户需提供实名认证信息并经过乐活通平台审核后方可成为服务者。企业用户需通过乐活通平台提交企业资质，资质审核通过后方可成为服务者。
				</p>
				<p>
					1.4雇主：指通过下单购买服务或通过发布需求购买服务，并按照事先承诺（如有）向服务者支付一定费用的用户。
				</p>
				<p>
					1.5费用：本协议所称&ldquo;费用&rdquo;包含服务者提供服务或完成服务的必须费用。费用由服务者和雇主双方自行协商，但不得超过法律法规的限制性规定。
				</p>
				<p><strong>二、服务者规范</strong></p>
				<p>您在乐活通平台上以服务者身份从事相关活动时，应遵守如下规范：</p>
				<p>
					2.1服务者在乐活通平台的任何行为，应当遵守本协议以及服务者与乐活通平台签署的其他协议，同时应遵守平台发布的平台规范、通知、公告、操作规则、帮助文档、温馨提示等（统称为交易规则），交易规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用乐活通平台服务，视为您同意上述补充协议。
				</p>
				<p>
					2.2服务者发布的信息和提供的服务，应由其自身获得并提供所需全部合法资质和能力，并就其在乐活通平台所发布之信息和所提供之服务独立承担法律责任。服务者应根据乐活通及乐活通平台的要求提供身份信息和所需服务资质，进行实名验证。
				</p>
				<p>
					2.3服务者应遵守法律法规，对服务者从事任何涉嫌违反法律法规以及本协议及其他相关协议的行为，乐活通平台有权按照本协议及交易规则进行处理；本协议及交易规则尚无规定的，乐活通平台有权按照具体事件酌情处理。但乐活通平台对服务者的处理不免除服务者应承担的法律责任。
				</p>
				<p>
					2.4服务者提供的服务，包括但不限于服务提供方式、服务内容和服务质量，均应符合相关法律法规的规定以及本协议的约定。
				</p>
				<p>
					2.5服务者在乐活通平台提供服务期间，服务者需向雇主提供及时有效服务，并且自行承担服务期间所产生的费用。
				</p>
				<p>
					2.6为保障雇主对服务进行正确的识别、辨别和区分，保障雇主的知情权、选择权，服务名称应当与服务存在关联性，不得以任何形式使雇主产生混淆、误解，或以任何方式侵犯他人合法权益。服务者应通过平台发布真实、准确、完整、合法的服务信息，服务者应就其发布的服务信息独立承担法律责任。
				</p>
				<p>
					2.7服务者应当尊重雇主知情权、选择权，服务者应当坚持诚实原则，不误导、欺诈雇主，尊重雇主的隐私，不骚扰雇主，不制造垃圾信息。
				</p>
				<p><strong>三、内容发布规范</strong></p>
				<p>
					您在乐活通平台上以服务者或雇主身份发布相关内容时，应遵守如下乐活通平台对服务者或雇主内容发布要求：
				</p>
				<p>3.1服务标题、图片、属性、价格、服务描述等服务要素之间应当互相匹配对应。</p>
				<p>
					3.2服务者/雇主发布的图片必须清晰，必须突出主题，不得含有其他网站、商户、LOGO、联系方式（包含但不限于手机号、网站链接、微信号、二维码）、支付宝、银行卡账号信息等。
				</p>
				<p>
					3.3服务者/雇主所发布之内容信息中不得存在任何违反国家法律法规、违反公序良俗或侵害第三方合法权益的信息。
				</p>
				<p>
					3.4服务者/雇主提供的所有内容（包含但不限于logo、图片、名称、文字等），均应是真实、有效、合法的，并与服务的实际情况一致。
				</p>
				<p>
					3.5服务者/雇主发布的服务信息内容不得违反《中华人民共和国广告法》及其他相关法律法规的规定，包括但不限于使用&ldquo;唯一&rdquo;、&ldquo;最优&rdquo;等夸大宣传或过度承诺的用语，也不得进行虚假宣传。
				</p>
				<p>
					3.6服务者/雇主发布的服务信息不得出现或含有外部链接或其他可直接跳转到第三方网站的功能，不得出现第三方网站的标识。
				</p>
				<p>
					3.7在交易过程中，服务者应当按照约定（包括但不限于订单内容、服务者另行向雇主作出的服务承诺）向雇主提供服务。
				</p>
				<p>
					3.8服务者不得强迫、诱导或欺骗雇主在乐活通平台之外进行交易，不得以任何手段逃避乐活通平台的线上交易流程。
				</p>
				<p>
					3.9未经乐活通平台书面授权，服务者不得在其服务标题、描述、图片或其他页面使用&ldquo;乐活通&rdquo;、或乐活通平台关联公司的商号、商标、服务标志、企业名称、其他标志、标识、用语或上述品牌的附属标志及图案的任何变体、缩写或错误拼音。
				</p>
				<p>3.10服务者不得通过虚假交易、虚假宣传、提供虚假数据等方式提高其服务的订购销量。</p>
				<p>3.11服务者应当诚实守信，不得以恶意评价、恶意投诉、诋毁、虚假订购等方式进行不正当竞争。</p>
				<p>3.12服务者发布的服务需要确保不存在安全隐患问题，且符合国家质监等部门的要求。</p>
				<p>
					3.13服务者不得在乐活通平台的服务详情页，明示或暗示与乐活通或乐活通平台及其关联公司有任何隶属关系或授权关系，或服务得到乐活通平台的认可，不得以乐活通的名义从事任何活动。
				</p>
				<p>3.14乐活通平台只提供信息撮合对接服务，不直接提供或出售商品。</p>
				<p><strong>四、乐活通平台管理规范</strong></p>
				<p>
					4.1为保障您认证信息的持续真实有效，维护用户权益，乐活通平台有权通过定期或不定期复核的方式，验证认证信息的真实有效性，您应按照乐活通平台的要求及时提供其届时有效的认证信息。如有关行政机关、司法机关或其他有权机关根据法律法规规定要求乐活通平台服务者的认证信息，乐活通平台可以依法向该等机关提供。如在复核过程中您提供的认证信息不全、过期、无效或虚假的，乐活通平台将依据情形严重程度，采取临时性的监管措施。
				</p>
				<p>4.2您应遵照乐活通平台交易流程的各项要求进行交易。</p>
				<p>
					4.3如服务者或其发布的服务存在违规行为，乐活通平台将根据违规行为的程度给予不同类型的处理，并对服务者保留追求法律责任的权利。
				</p>
				<p><strong>五、违规行为</strong></p>
				<p>
					<strong>5.1</strong><strong>您作为服务者和/</strong
					><strong>或雇主的身份出现任意一种违反本协议的行为（&ldquo;</strong><strong>违规行为&rdquo;</strong
					><strong
						>），或为违规行为提供便利，乐活通平台有权对该违规行为进行独立判断并针对您发布的内容采取违规处理措施。</strong
					>
				</p>
				<p><strong>5.2</strong><strong>您不得出现任何违反法律法规的行为，包括但不限于：</strong></p>
				<table>
					<tbody>
						<tr>
							<td rowspan="34" width="94">
								<p><strong>内容违规</strong></p>
							</td>
							<td width="149">
								<p><strong>政治敏感</strong></p>
							</td>
							<td>
								<p><strong>涉及政治的信息及服务</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="2" width="149">
								<p><strong>黄色低俗</strong></p>
							</td>
							<td>
								<p><strong>低俗视频聊天或语音聊天</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>提供低俗色情服务和信息</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="3" width="149">
								<p><strong>赌博</strong></p>
							</td>
							<td>
								<p><strong>传授赌博技巧</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>提供赌博工具</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>提供赌博场所</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="3" width="149">
								<p><strong>毒品</strong></p>
							</td>
							<td>
								<p><strong>毒品买卖信息</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>提供吸毒工具</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>提供吸毒场所</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="4" width="149">
								<p><strong>军警治安类</strong></p>
							</td>
							<td>
								<p><strong>非正规渠道的军警</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>打架、斗殴等危险行为</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>办证刻章</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>其他会危害社会治安的行为</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="3" width="149">
								<p><strong>医疗器械</strong></p>
							</td>
							<td>
								<p><strong>任何药品和医疗器械</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>胎儿性别鉴定</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>需要特殊资质的医疗服务、卫生保健咨询服务、精神治疗等</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="4" width="149">
								<p><strong>网络服务类</strong></p>
							</td>
							<td>
								<p><strong>炒信、刷单</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>转支付、通过乐活通平台进行借贷</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>涉外婚介</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>黑客相关服务</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="4" width="149">
								<p><strong>侵害隐私类</strong></p>
							</td>
							<td>
								<p>
									<strong
										>身份证件（包括但不限于身份证、护照、驾驶证）和银行卡（包括但不限于借记卡、信用卡）等的伪造和变造</strong
									>
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>私家侦探类服务</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>偷拍、跟踪等</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>其他危害其他隐私的行为</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="4" width="149">
								<p><strong>金融类</strong></p>
							</td>
							<td>
								<p><strong>货币兑换</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>假币相关</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>非法集资</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>信用卡套现服务</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="3" width="149">
								<p><strong>通讯/</strong><strong>数码类</strong></p>
							</td>
							<td>
								<p><strong>虚假基站</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>发送虚假、骚扰悬心</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>卫星天线等侵入服务</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="2" width="149">
								<p><strong>其他内容违规</strong></p>
							</td>
							<td>
								<p><strong>代考、代写论文、考试作弊</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>代孕</strong></p>
							</td>
						</tr>
						<tr>
							<td width="149">
								<p><strong>限售类</strong></p>
							</td>
							<td>
								<p><strong>缺少资质</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="3" width="94">
								<p><strong>弄虚作假</strong></p>
							</td>
							<td rowspan="2" width="149">
								<p><strong>账号、资料虚假</strong></p>
							</td>
							<td>
								<p><strong>盗用他人网络账号</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>盗用他人证件或注册账号、密码等，发布服务</strong></p>
							</td>
						</tr>
						<tr>
							<td width="149">
								<p><strong>虚假宣传</strong></p>
							</td>
							<td>
								<p><strong>服务者虚假宣传和乐活通平台约定之合作关系</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="10" width="94">
								<p><strong>&nbsp;</strong></p>
								<p><strong>&nbsp;</strong></p>
								<p><strong>&nbsp;</strong></p>
								<p><strong>服务违规</strong></p>
							</td>
							<td rowspan="3" width="149">
								<p><strong>服务描述不规范</strong></p>
							</td>
							<td>
								<p><strong>服务地域范围错误</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>服务时间错误</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>服务内容与线上描述不符</strong></p>
							</td>
						</tr>
						<tr>
							<td width="149">
								<p><strong>服务质量</strong></p>
							</td>
							<td>
								<p><strong>服务内容不满意造成的客诉</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="3" width="149">
								<p><strong>服务安全</strong></p>
							</td>
							<td>
								<p><strong>造成雇主门诊、住院治疗的行为</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>造成其他人伤害</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>造成雇主财产损失</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="3" width="149">
								<p><strong>违背承诺、未提供服务或提供的服务与线上描述不一致</strong></p>
							</td>
							<td>
								<p><strong>交易成功后未提供服务</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>服务者同意接单后未提供服务</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>线下虚假宣传</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="6" width="94">
								<p><strong>服务者行为违规</strong></p>
							</td>
							<td rowspan="2" width="149">
								<p><strong>损害乐活通名誉</strong></p>
							</td>
							<td>
								<p><strong>公开发表关于乐活通平台的负面言论</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>散布关于乐活通平台服务的不实言论</strong></p>
							</td>
						</tr>
						<tr>
							<td rowspan="2" width="149">
								<p><strong>不正当谋利</strong></p>
							</td>
							<td>
								<p>
									<strong
										>诱使乐活通平台及其关联人士做出违背乐活通平台规范、损害乐活通平台利益的行为</strong
									>
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p><strong>服务者任何规避市场管控措施</strong></p>
							</td>
						</tr>
						<tr>
							<td width="149">
								<p><strong>违反保密义务</strong></p>
							</td>
							<td>
								<p><strong>服务者为谋取私利违反保密义务</strong></p>
							</td>
						</tr>
						<tr>
							<td width="149">
								<p><strong>侵犯知识产权</strong></p>
							</td>
							<td>
								<p><strong>发布的服务信息不具备合法权利和权益，侵犯他人知识产权</strong></p>
							</td>
						</tr>
						<tr>
							<td width="94">
								<p><strong>交易类风险</strong></p>
							</td>
							<td colspan="2" width="474">
								<p><strong>风险交易</strong></p>
							</td>
						</tr>
					</tbody>
				</table>
				<p><strong>&nbsp;</strong></p>
				<p><strong>六、违规处理措施及责任追究</strong></p>
				<p><strong>&nbsp;</strong></p>
				<p>
					<strong>6.1</strong
					><strong
						>乐活通平台有权以普通或非专业人员的认知水平标准对服务者的行为进行独立判断，并按本协议予以处理或中止、终止向服务者提供服务（包括中止或终止本协议，下同），且无需事先征得服务者的同意。</strong
					>
				</p>
				<p>
					<strong>6.2</strong
					><strong
						>服务者应当遵守本协议及乐活通平台已经发布及将来可能发布的各类交易规则；如违反本协议和交易规则，乐活通平台有权对服务者行为及应适用的规则进行独立认定，针对服务者或服务者所发布的服务涉及的违规行为采取如下意向或多项处理措施：</strong
					>
				</p>
				<p><strong>&nbsp;</strong></p>
				<table>
					<tbody>
						<tr>
							<td width="284">
								<p><strong>处理措施</strong></p>
							</td>
							<td width="284">
								<p><strong>说明</strong></p>
							</td>
						</tr>
						<tr>
							<td width="284">
								<p>服务暂停</p>
							</td>
							<td width="284">
								<p>即服务不可被搜索、不可被预约</p>
							</td>
						</tr>
						<tr>
							<td width="284">
								<p>服务下架</p>
							</td>
							<td width="284">
								<p>即服务停止，不可被搜索、不可展示、不可被交易</p>
							</td>
						</tr>
						<tr>
							<td width="284">
								<p>服务者限权</p>
							</td>
							<td width="284">
								<p>即服务者不可发布新的服务，所有服务不得修改，冻结账户资金</p>
							</td>
						</tr>
						<tr>
							<td width="284">
								<p>解除合作</p>
							</td>
							<td width="284">
								<p>即服务者不可发布新的服务、所有在线服务下架、不可在乐活通平台继续交易</p>
							</td>
						</tr>
						<tr>
							<td width="284">
								<p>要求服务者支付违约金及赔偿损失</p>
							</td>
							<td width="284">
								<p>
									即按照服务者违规行为的性质、造成损失的大小等要求服务者支付违约金（具体违约金收取规则以交易规则及《乐活通平台合作合同》为准），并赔偿由此给乐活通平台造成的全部损失（损失包括自身的直接损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），针对前述款项乐活通平台有权自对服务者应付款及保证金中直接扣收。
								</p>
								<p>&nbsp;</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p><strong>注：乐活通平台有权根据违规严重程度判定是否对服务者解除其所有与乐活通平台的合作。</strong></p>
				<p><strong>七、责任限制</strong></p>
				<p>
					<strong>7.1 </strong
					><strong
						>乐活通平台依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，乐活通平台并不承担相应的违约责任：</strong
					>
				</p>
				<p>
					<strong>7.1.1</strong
					><strong>因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</strong>
				</p>
				<p><strong>7.1.2</strong><strong>因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</strong></p>
				<p>
					<strong>7.1.3</strong
					><strong
						>在乐活通平台已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素；</strong
					>
				</p>
				<p><strong>7.1.4 </strong><strong>因第三方的行为或者您自身原因造成的损失。</strong></p>
				<p>
					<strong>7.2 </strong
					><strong
						>您明确了解并同意，使用本服务之风险由您个人负担，除非此种风险或损害系由乐活通平台自身过错所直接导致。除非另有明确的书面说明，本平台及其所包含的或以其他方式通过本平台提供给您的全部信息、内容、材料、产品（包括软件）和本服务，均是在"</strong
					><strong>现状"</strong><strong>和按"</strong><strong>现有"</strong><strong>基础提供的。</strong>
				</p>
				<p>
					<strong>7.3 </strong
					><strong
						>除非另有明确的书面说明，乐活通平台不对平台运营及其平台上的全部信息、内容、材料、产品（包括软件）或本服务作任何形式的明示或默示的担保或保证（中华人民共和国法律另有规定的除外）。</strong
					>
				</p>
				<p>
					<strong>7.4 </strong
					><strong>乐活通平台对于任何自本平台而获得的他人信息或者广告宣传等任何资讯（以下统称"</strong
					><strong>信息"</strong><strong>），不保证真实、准确和完整性。如果任何单位或者个人通过上述"</strong
					><strong>信息"</strong
					><strong
						>而进行任何行为，须自行甄别真伪和谨慎预防风险。否则，无论何种原因，本平台不对任何非与本平台直接发生的交易和/</strong
					><strong>或行为承担任何直接、间接、附带或衍生的损失和责任。</strong>
				</p>
				<p>
					<strong>7.5 </strong
					><strong
						>您理解并同意，在争议调处服务中，乐活通平台的客服并非专业人士，仅能以普通人的认知对您提交的凭证进行判断。因此，除存在故意或重大过失外，乐活通平台对争议调处决定免责。</strong
					>
				</p>
				<p><strong>八、信息的保护及授权</strong></p>
				<p>
					8.1
					您声明并保证，您对您所发布的信息拥有合法的权利。否则，乐活通平台可对您发布的信息依法或依本协议进行删除或屏蔽。
				</p>
				<p>
					8.2
					乐活通平台保证不对外公开或向第三方（乐活通平台的关联方除外）提供您的注册资料及您在使用服务时存储在乐活通平台的非公开内容，但下列情况除外：
				</p>
				<p>8.2.1事先获得您的明确授权；</p>
				<p>8.2.2系为履行您的订单或保护您的合法权利所必须；</p>
				<p>8.2.3相应的法律程序及相关政府部门要求乐活通平台提供的。</p>
				<p>
					8.3
					乐活通平台可能会与第三方合作向您提供相关服务，同时，为了更好向您提供服务、推荐商品/服务及开展市场调查与信息数据分析等，乐活通平台可能需要向合作方披露您的相关信息，在此情况下，乐活通平台有权将您的相关信息提供给该第三方。
				</p>
				<p>8.4 在不透露您隐私资料的前提下，乐活通平台有权对数据库进行分析并对数据库进行商业上的利用。</p>
				<p>
					8.5
					为方便您使用乐活通平台的物流、金融、通讯等相关服务，您同意并授权乐活通平台将您在账户注册和使用乐活通平台服务过程中提供、形成的信息传递给乐活通平台的其他相关服务提供者，或从乐活通平台其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。您同意不会因此追究乐活通平台相关服务提供者的责任。
				</p>
				<p>
					8.6
					对于您提供、发布及在使用乐活通平台服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，在法律规定的保护期限内您免费授予乐活通获得全球排他的许可使用权利及再授权给其他第三方使用并可以自身名义对第三方侵权行为取证及提起诉讼的权利。您同意乐活通存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。
				</p>
				<p><strong>九、知识产权</strong></p>
				<p>
					9.1
					您一旦接受本协议，即表明您主动将其在任何时间段在乐活通平台发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章、视频等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给乐活通平台所有，您同意乐活通平台有权就任何主体侵权而单独提起诉讼。
				</p>
				<p>
					9.2
					乐活通平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片断、数字下载、数据编辑和软件），均是乐活通平台或其内容提供者的财产，受中国和国际版权法的保护，乐活通平台所有的内容的汇编是乐活通平台的排他财产，受中国和国际版权法的保护，乐活通平台上所有软件都是乐活通或其软件供应商的财产，受中国和国际版权法的保护。
				</p>
				<p>
					9.3除法律另有强制性规定外，未经乐活通平台书面许可，您不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用乐活通平台的信息内容，否则，乐活通平台有权追究您法律责任。
				</p>
				<p><strong>十、通知及送达</strong></p>
				<p>
					10.1
					您在注册成为乐活通平台用户，并接受乐活通平台服务时，您应该向乐活通平台提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。
				</p>
				<p>您在注册乐活通平台用户时生成的用于登陆乐活通平台接收消息的账号，也作为您的有效联系方式。</p>
				<p>
					10.2
					乐活通平台将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。
				</p>
				<p>
					10.3
					乐活通平台通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在乐活通平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的客户端推送的消息、系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
				</p>
				<p>
					10.4
					对于在乐活通平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院、仲裁机构）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书），并同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。
				</p>
				<p>
					10.5
					您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不准确，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。
				</p>
				<p><strong>十一、协议的变更、终止</strong></p>
				<p>
					11.1乐活通有权根据法律法规调整及平台运营需要不定期修订本协议；修改后的协议条款在乐活通平台公示7日后生效，并代替原来的协议；若您在本协议内容公告变更后继续使用本服务的，表示您已充分阅读、理解并接受变更后的协议内容，也将遵守变更后的协议内容使用乐活通平台服务；如果您不接受修订的内容，需立即停止接受乐活通平台提供的服务。
				</p>
				<p>
					11.2您的帐号被限制或注销后，您使用相关服务的权利同时终止，乐活通没有义务向您或第三方提供您使用服务所形成的信息资料。
				</p>
				<p>11.3 乐活通可随时根据战略规划或市场行情中断一项或多项服务，并不就此向任何人承担责任。</p>
				<p>
					11.4
					本协议终止后，对于您在本协议存续期间产生的交易订单，乐活通平台可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。
				</p>
				<p><strong>十二、法律管辖适用及其他</strong></p>
				<p>
					12.1
					本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区适用之有效法律（但不包其冲突法规则）。
				</p>
				<p>
					12.2
					如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
				</p>
				<p>
					12.3您因使用乐活通平台服务所产生及与平台服务有关的争议，由乐活通平台与您协商解决。协商不成时，任何一方均应向本协议签订地北京市朝阳区有管辖权的法院提起诉讼。
				</p>
				<p>
					12.4
					特别提示：由于各服务方提供商品/服务存在一定差异性，尤其是法律法规特别规定或平台有特殊规则的，以上述特别规定或特殊规则为准。如有疑问，建议您详询乐活通平台或服务方客服后再行购买或接受服务。
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			type: 0,
			title: '',
			showPrivacy: false,
			showUser: false,
		}
	},
	components: {},
	methods: {
		onLoad(options) {
			this.type = options.type
			console.log('type=' + this.type)
			if (this.type == 0) {
				this.showUser = true
				this.showPrivacy = false
				this.title = '乐活通平台服务协议'
			} else {
				this.title = '乐活通隐私政策'
				this.showPrivacy = true
				this.showUser = false
			}
		
		},
		getMessage () {
			
		},
		clickLeft () {
      this.$route.go(-1)
		},
	},
}
</script>

<style>
.view-colum {
	flex-direction: column;
	padding: 12rpx;
}
</style>
