var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p_container"},[_c('el-container',{class:[
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '8',
        '9' ].includes(_vm.routerKey)
        ? 'nav-bg-img'
        : ''},[_c('el-header',{class:_vm.routerKey === '7-2-1' ? 'el-header1' : 'el-header'},[_c('TopNav',{attrs:{"routerKey":_vm.routerKey},on:{"loginVisible":_vm.loginVisible}})],1),_c('el-main',[_c('router-view')],1),_c('el-footer',[_c('Footer')],1)],1),(_vm.loginIsShow)?_c('Login',{attrs:{"loginIsShow":_vm.loginIsShow},on:{"loginVisible":_vm.loginVisible}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }