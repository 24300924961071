<template>
  <div class="login-mask flex" v-if="loginIsShow" @click="loginVisible">
    <div class="login-box flex" @click.stop>
      <div class="login-title">
        <i class="el-icon-close" @click="loginVisible"></i>
        <div class="text1">国美家帐号</div>
        <div class="text2">登录国美家帐号，享受各平台同步服务</div>
      </div>
      <el-form
        class="login-form"
        :rules="loginRules"
        ref="loginForm"
        :model="loginForm"
        label-width="0"
      >
        <el-form-item prop="phone">
          <el-input
            size="small"
            @keyup.enter.native="loginSubmit"
            v-model="loginForm.phone"
            auto-complete="off"
            placeholder="手机号"
            maxlength="11"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="code" class="login-form-code">
          <el-input
            size="small"
            @keyup.enter.native="loginSubmit"
            v-model="loginForm.code"
            auto-complete="off"
            placeholder="验证码"
            maxlength="6"
            clearable
          >
            <template slot="append">
              <span
                @click="sendCode"
                class="msg-text"
                :class="[{ display: msgKey }]"
                >{{ msgText }}</span
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            @click.native.prevent="loginSubmit"
            class="login-submit"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { isvalidatemobile } from "@/utils/validate";
import { sendSmsCode } from "@/api";
export default {
  props: {
    loginIsShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (isvalidatemobile(value)[0]) {
        callback(new Error(isvalidatemobile(value)[1]));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value.length != 6) {
        callback(new Error("请输入6位数的验证码"));
      } else {
        callback();
      }
    };
    return {
      msgText: "", //验证码按钮
      msgTime: "", //验证码倒计时间
      msgKey: false, //验证码按钮60秒之后才可以点击
      loginForm: {
        phone: "",
        code: "",
      },
      loginRules: {
        phone: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhone,
          },
        ],
        code: [
          {
            required: true,
            trigger: "blur",
            validator: validateCode,
          },
        ],
      },
    };
  },
  computed: {
    config() {
      return {
        MSGINIT: "获取验证码",
        MSGSCUCCESS: "s后重新获取",
        MSGTIME: 60,
      };
    },
  },
  created() {
    this.msgText = this.config.MSGINIT;
    this.msgTime = this.config.MSGTIME;
  },
  mounted() {},
  methods: {
    // 登陆弹框事件
    loginVisible() {
      this.$emit("loginVisible", false);
    },
    async sendCode() {
      if (this.msgKey) return;
      //   增加已发送验证码的信息提示
      //   const h = this.$createElement;
      //   this.$message({
      //     message: h(
      //       "div",
      //       {
      //         style:
      //           "width:299px;height:58px;background-color:#5c5c5c;color:#fff;font-size:14px;text-align:center;line-height:58px",
      //       },
      //       "验证码发送成功"
      //     ),
      //   });
      //已发送验证码的信息提示代码结束
      this.$refs.loginForm.validateField("phone", async (error) => {
        if (!error) {
          const res = await sendSmsCode({ phoneNum: this.loginForm.phone });
          console.log(res);
          if (res.data.code === 0) {
            this.msgText = this.msgTime + this.config.MSGSCUCCESS;
            this.msgKey = true;
            const time = setInterval(() => {
              this.msgTime--;
              this.msgText = this.msgTime + this.config.MSGSCUCCESS;
              if (this.msgTime == 0) {
                this.msgTime = this.config.MSGTIME;
                this.msgText = this.config.MSGINIT;
                this.msgKey = false;
                clearInterval(time);
              }
            }, 1000);
          } else {
            this.$message(res.data.message);
          }
        } else {
          return false;
        }
      });
    },
    loginSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          console.log(this.loginForm);
          const params = {
            phoneNum: this.loginForm.phone,
            smsCode: this.loginForm.code,
          };
          this.$store.dispatch("loginByPhone", params).then((res) => {
            if (res.data.code === 0) {
              this.$store.dispatch("getMerchantCode", {
                phone: this.loginForm.phone,
              });
              this.loginVisible();
            } else {
              this.$message(res.data.message);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/* 登陆弹框 */
.login-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  .login-box {
    width: 580px;
    background: url("~@/assets/img/login_1.png") no-repeat center 0;

    min-height: 573px;
    flex-direction: column;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    justify-content: space-between;
    position: relative;
    .el-icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      padding: 10px;
      cursor: pointer;
    }
    .login-title {
      text-align: center;
      .text1 {
        color: #ffffff;
        font-size: 26px;
        margin: 65px auto 15px;
      }
      .text2 {
        font-size: 14px;
        color: #ffffff;
      }
    }

    .login-form {
      width: 340px;
      margin-bottom: 89px;
      /deep/.el-input__inner {
        font-size: 14px;
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
        padding: 0;
      }
      /deep/.el-form-item.is-error .el-input__inner {
        border-color: #f41935;
      }
      /deep/.el-form-item__error {
        top: -25%;
        color: #f41935;
      }
      /deep/.el-input-group__append {
        background: #ffffff;
        border-radius: 3px;
        font-size: 12px;
        color: #ff8600;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
        transform: translate(-100%, -10px);
        border: 1px solid #ea8528;
      }
      .login-form-code {
        /deep/.el-input__inner {
          width: 340px;
          padding-right: 160px;
        }
        /deep/.el-input__suffix {
          // transform: translateX(-260px) !important;
        }
        /deep/.el-form-item__error {
          top: -40%;
        }
      }
      /deep/.login-submit {
        width: 100%;
        background: linear-gradient(to right, #f4a937, #eb8729);
        border-radius: 3px;
        font-size: 16px;
        color: #ffffff;
        margin-top: 52px;
        border: 0 !important;
      }
    }
  }
}

// .el-message {
//   min-width: 0 !important;
//   padding: 0 !important;
// }
</style>
