import * as types from './mutation-types'

export default {
  [types.SET_DICTIONARY_LIST](state, payload) {
    state.dictionaryList = payload
  },
  [types.SET_REGION_LIST](state, payload) {
    state.regionList = payload
  },
  [types.SET_BANK_LIST](state, payload) {
    state.bankList = payload
  },
  [types.SET_COMPANY_LIST](state, payload) {
    state.companyList = payload
  },
	[types.SET_USERNAME](state, userName) {
		state.userName = userName
	},
	[types.QUIT](state) {
		state.userName = ''
	},
  [types.SET_LIST_LOADING](state, payload) {
    state.listLoading = payload
  },
  [types.SET_UPDATE_STATUS](state, payload) {
    state.updateStatus = payload
  }
}