import Vue from "vue";
import Router from "vue-router";

import home from "@/views/home.vue";
import userAgreement from "@/views/merchant/userAgreement.vue";
import newsDetail1 from "@/views/newsDetail1.vue";
import newsDetail2 from "@/views/newsDetail2.vue";

Vue.use(Router);

const router = new Router({
  linkActiveClass: "active",
  hashbang: true, // 将路径格式化为#!开头
  history: true, // 启用HTML5 history模式，可以使用pushState和replaceState来管理记录
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      redirect: "/index/index",
      path: "/index",
      component: home,
      children: [
        // 首页
        {
          path: "index",
          name: "index",
          component: () => import("@/views/index.vue"),
          meta: {
            key: "0",
          },
        },
        // 国家美
        {
          path: "gome",
          name: "gome",
          component: () => import("@/views/gome.vue"),
          meta: {
            key: "1",
          },
        },
        // 药品信息
        /*{
				path: 'drug',
				name: 'drug',
				component: () => import('@/views/drug.vue'),
				meta: {
					key: '2'
				}
			},*/
        // 商业模式
        {
          path: "business",
          name: "business",
          component: () => import("@/views/business"),
          meta: {
            key: "2",
          },
        },
        // 新闻中心
        {
          path: "news",
          name: "news",
          component: () => import("@/views/news.vue"),
          meta: {
            key: "5",
          },
        },
        // 项目介绍
        {
          path: "project",
          name: "project",
          component: () => import("@/views/project.vue"),
          meta: {
            key: "3",
          },
        },
        // 加入我们
        {
          path: "jion",
          name: "jion",
          component: () => import("@/views/jion.vue"),
          meta: {
            key: "8",
          },
        },
        // 招商入驻
        // {
        //     path: 'investmentIn',
        //     name: 'investmentIn',
        //     component: () =>
        //         import ('@/views/merchant/mallGuide.vue'),
        //     meta: {
        //         key: '4'
        //     }
        // },
        // 撮合入住简介
        {
          path: "businessGuide",
          name: "businessGuide",
          component: () => import("@/views/merchant/businessGuide.vue"),
          meta: {
            key: "7-1",
          },
        },
        {
          path: "mallGuide",
          name: "mallGuide",
          component: () => import("@/views/join/index.vue"),
          meta: {
            key: "4-1",
          },
        },
        // 商mall入住简介
        // {
        //   path: "mallGuide",
        //   name: "mallGuide",
        //   component: () => import("@/views/merchant/mallGuide.vue"),
        //   meta: {
        //     key: "4",
        //   },
        // },
        // 商户入驻流程
        {
          path: "merchantInfo",
          name: "merchantInfo",
          component: () => import("@/views/merchant/merchantInfo.vue"),
          meta: {
            key: "7-2-1",
          },
        },
        // 商户入驻流程
        {
          path: "companyInfo",
          name: "companyInfo",
          component: () => import("@/views/merchant/CompanyInfo.vue"),
          meta: {
            key: "7-2-2",
          },
        },
        // 商户入驻流程
        {
          path: "brandInfo",
          name: "brandInfo",
          component: () => import("@/views/merchant/brandInfo.vue"),
          meta: {
            key: "7-2-3",
          },
        },
        {
          path: "submitInfo",
          name: "submitInfo",
          component: () => import("@/views/merchant/submitInfo"),
          meta: {
            key: "7-2-4",
          },
        },
        // 联系方式
        {
          path: "contactWay",
          name: "contactWay",
          component: () => import("@/views/contactWay.vue"),
          meta: {
            key: "6",
          },
        },
        // 药品详情
        {
          path: "drugDetail",
          name: "drugDetail",
          component: () => import("@/views/drugDetail.vue"),
          meta: {
            key: "2",
          },
        },
        // 手机APP
        {
          path: "downLoadApp",
          name: "downLoadApp",
          component: () => import("@/views/downloadApp.vue"),
          meta: {
            key: "7",
          },
        },
      ],
    },
    {
      path: "/userAgreement",
      component: userAgreement,
    },
    {
      path: "/newsDetail1",
      component: newsDetail1,
    },
    {
      path: "/newsDetail2",
      component: newsDetail2,
    },
    {
      path: '/joinType',
      name: 'JoinType',
      component: () => import('@/views/join/match/layout'),
      redirect: '/joinType/match',
      children: [
        {
          path: '/joinType/match',
          name: 'match',
          component: () => import('@/views/join/match'),
          meta: {
            key: "10-1",
          },
        }
      ]
    },
    /**
     * 官网自主入驻
     * @time 20220321
     * @auth modenghui@gome.com.cn
     */
    {
      path: '/mall',
      name: 'JoinMall',
      component: () => import('@/views/join/mall/layout'),
      redirect: '/mall/step/preInfo',
      children: [
        {
          path: 'step/mall',
          name: 'MallIndex',
          component: () => import('@/views/join/mall'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'step/preInfo',
          name: 'StepPreInfo',
          component: () => import('@/views/join/mall/step/preInfo'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'step/contactInfo',
          name: 'StepContactInfo',
          component: () => import('@/views/join/mall/step/contactInfo'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'step/companyInfo',
          name: 'StepCompanyInfo',
          component: () => import('@/views/join/mall/step/companyInfo'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'step/brandInfo',
          name: 'StepBrandInfo',
          component: () => import('@/views/join/mall/step/brandInfo'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'status/submitted',
          name: 'StatusSubmitted',
          component: () => import('@/views/join/mall/status/submitted'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'status/queryProgress',
          name: 'StatusQueryProgress',
          component: () => import('@/views/join/mall/status/queryProgress'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'status/progressing',
          name: 'StatusProgressing',
          component: () => import('@/views/join/mall/status/progressing'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'status/completed',
          name: 'StatusCompleted',
          component: () => import('@/views/join/mall/status/completed'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'cases/detail1',
          name: 'CasesDetail1',
          component: () => import('@/views/join/mall/cases/demo1'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'cases/detail2',
          name: 'CasesDetail2',
          component: () => import('@/views/join/mall/cases/demo2'),
          meta: {
            key: "11-1",
          },
        },
        {
          path: 'cases/detail3',
          name: 'CasesDetail3',
          component: () => import('@/views/join/mall/cases/demo3'),
          meta: {
            key: "11-1",
          },
        }
      ]
    },
    /**
     * 官网自主入驻
     * 微信端 - 上传证件
     */
    {
      path: '/upload/license',
      name: 'UploadLicense',
      meta: {
        title: '国美家入驻'
      },
      beforeEnter: (to, from, next) => {
        document.title = to.meta.title;
        next();
      },
      component: () => import('@/views/join/mobile/uploadLicense')
    },
    {
      path: '/upload/bossId',
      name: 'UploadBossId',
      meta: {
        title: '国美家入驻'
      },
      beforeEnter: (to, from, next) => {
        document.title = to.meta.title;
        next();
      },
      component: () => import('@/views/join/mobile/uploadBossId')
    },
    /**
     * 官网自助入驻
     * 招商会
     */
    {
      path: '/conference',
      name: 'Conference',
      component: () => import('@/views/join/mall/layout'),
      redirect: '/conference/list',
      children: [
        {
          path: 'list',
          name: 'ConferenceList',
          component: () => import('@/views/join/menus/conference/list'),
          meta: {
            key: "11-5",
          },
        },
        {
          path: 'detail',
          name: 'ConferenceDetail',
          component: () => import('@/views/join/menus/conference/detail'),
          meta: {
            key: "11-5",
          }
        }
      ]
    },
     /**
    * 官网自助入驻
    * 资费资质
    */
     {
       path: '/qualification',
       name: 'Qualification',
       component: () => import('@/views/join/mall/layout'),
       redirect: '/qualification/qualificationInfo',
       children: [
        {
          path: 'qualificationInfo',
          name: 'QualificationInfo',
          component: () => import('@/views/join/menus/qualification/index'),
          meta: {
            key: "11-3",
          },
        }
      ]
     },
    /**
    * 官网自助入驻
    * 常见问题
    */
    {
       path: '/questions',
       name: 'Questions',
       component: () => import('@/views/join/mall/layout'),
       redirect: '/questions/questionsInfo',
       children: [
        {
          path: 'questionsInfo',
          name: 'questionsInfo',
          component: () => import('@/views/join/menus/questions/index'),
          meta: {
            key: "11-6",
          },
        },
        {
          path: 'contactList',
          name: 'contactList',
          component: () => import('@/views/join/menus/contactList/index'),
          meta: {
            key: "11-4",
          },
        }
      ]
     },
    /**
    * 官网自助入驻
    * 入驻流程准备
    */
    {
      path: '/process',
      name: 'Process',
      component: () => import('@/views/join/mall/layout'),
      redirect: '/process/processInfo',
      children: [
       {
         path: 'processInfo',
         name: 'ProcessInfo',
         component: () => import('@/views/join/menus/process/index'),
         meta: {
          key: "11-2",
        },
       }
     ]
    },
    {
      path: "*",
      redirect: "/index/index",
    },
  ],
});
//解决重复路由报错
const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
//
// router.beforeEach((to, from, next) => {
// 	document.documentElement.scrollTop = 0
// 	store.dispatch('updateHistoryLength') // 变化时更新路由切换长度
// 	next()
// })

export default router;
