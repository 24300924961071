export const SET_DICTIONARY_LIST = 'SET_DICTIONARY_LIST'
export const SET_REGION_LIST = 'SET_REGION_LIST'
export const SET_BANK_LIST = 'SET_BANK_LIST'
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST'
export const SET_LIST_LOADING = 'SET_LIST_LOADING'
export const SET_UPDATE_STATUS = 'SET_UPDATE_STATUS'

// 修改用户名 
export const SET_USERNAME = 'SET_USERNAME'
// 退出
export const 	QUIT = 'QUIT'
