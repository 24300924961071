<template>
  <el-container class="p_container">
    <el-main>
      <div class="news_wrap">
        <div class="gome_news">
          <h1>
            重庆沙坪坝区副区长、国际物流枢纽园区董事长一行拜会国美集团创始人黄光裕沟通交流
          </h1>
          <div class="gome_news_tip">
            <p>{{ data.tip }}</p>
          </div>
          <div class="gome_news_content">
            <p>
              2021年10月20日
              重庆沙坪坝区副区长代建红、国际物流枢纽园区董事长罗书权一行拜会国美集团创始人黄光裕。双方就重庆沙坪坝区国际物流枢纽园区与国美集团的合作进行深入交流。
            </p>
            <img src="@/assets/img/news_img3.png" alt="" />
            <p style="color: #999; font-size: 24px">
              （图注：国美家CEO洪聪贵、国美集团地产公司总裁陈焕智，国美集团地产总裁助理苏继东；重庆市沙坪坝区政府副区长代建红、国际物流枢纽园区董事长罗书权、重庆市商务委亚非处副处长石熹、国际物流枢纽园区招商部部长余
              芳等人员面对面交流）
            </p>
            <p class="finallyP">
              代区长介绍了重庆沙坪坝区投资环境及发展规划愿景，罗书权重点介绍了国际物流枢纽园区发展建设以及能够与国美集团进行深度合作的产业项目介绍。黄光裕介绍了国美集团在零售、地产、投资等板块的发展经营状况。黄光裕表示，国美集团始终履行国美、家美、生活美的社会责任，重庆是国美集团重要发展的核心城市之一，希望能与沙坪坝区政府及国际物流枢纽园区进行深入合作，双方成立项目专项小组就具体项目进行对接推进工作开展。会后代区长赠送黄光裕重庆蜀绣（中国非物质文化遗产）作为此次双方会晤代表重庆文化的小礼物。
            </p>
            <img src="@/assets/img/news_img4.png" alt="" />
          </div>
          <div class="gome_news_footer">
            <div class="gome_news_footer_title">
              <h3>更多精彩内容</h3>
              <div></div>
            </div>
          </div>
        </div>
        <!-- 放在外层与浏览器同宽 -->
        <div class="gome_news_card">
          <div class="gome_news_n">
            <div class="right_card">
              <div class="gome_news-content">
                <!-- 加一层div遮罩隐藏掉滚动条 -->
                <div class="gome_overflow_n">
                  <div
                    class="gome_news-content-con"
                    v-for="item in items"
                    :key="item.idx"
                    @click="toNewsDetail(item.idx)"
                  >
                    <img :src="item.image" alt="" />

                    <div class="gome_news-content-con-title">
                      <h3 class="gome_news-content-con-title-name">
                        {{ item.name }}
                      </h3>
                      <p class="gome_news-content-con-title-des">
                        {{ item.des }}
                      </p>
                      <h2 class="gome_news-content-con-title-time">
                        {{ item.time }}
                      </h2>
                    </div>
                    <p class="gome_news-content-con-more">
                      阅读全部>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer>
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  // 注册子组件
  components: {
    Footer,
  },
  data() {
    return {
      items: [
        {
          idx: "1",
          image: require("@/assets/img/news_img1.png"),
          name: "让蓉城人民再“安逸”...",
          des:
            "近年来，向来以“安逸”著称的成都，正以前所未有的速度快速发展，在国内经济发展浪潮中...",
          date: "2021.08.23",
        },
        {
          idx: "2",
          image: require("@/assets/img/news_img3.png"),
          name: "重庆沙坪坝区副区长...",
          des:
            "2021年10月20日 重庆沙坪坝区副区长代建红、国际物流枢纽园区董事长罗书权一行拜会...",
          date: "2021.10.20",
        },
      ],
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        title: "成都市青羊区领导莅临国美集团",
        tip: "国美家动态",
      }),
    },
  },
  methods: {
    enter_news() {},
    toNewsDetail(idx) {
      this.$router.push(`/newsDetail${idx}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.p_container {
  width: 100%;
  background: #fff;
  overflow-x: auto;
  .el-footer {
    padding: 0;
    overflow-x: auto;
    width: 100%;
    height: auto !important;
    background: #1e222c;
  }

  .el-main {
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }
}

.news_wrap {
  width: 100%;
  overflow-x: auto;
  .gome_news {
    width: 1200px;
    margin: auto;
    position: relative;
    text-align: left;
    margin-top: 182px;
    h1 {
      font-size: 60px;
      font-family: MicrosoftYaHei;
      font-weight: normal;
      color: #1f2329;
      line-height: 90px;
    }
    .gome_news_tip {
      width: 126px;
      height: 38px;
      margin-top: 40px;
      background: #feead2;
      border-radius: 4px;
      text-align: center;
      p {
        line-height: 38px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #f08107;
      }
    }
    .gome_news_content {
      margin-top: 60px;
      p {
        font-size: 28px;
        font-family: MicrosoftYaHei;
        color: #333333;
        // line-height: 50px;
        margin-top: 60px;
      }
      img {
        width: 1200px;
        margin-top: 60px;
      }
      .finallyP {
        margin-bottom: 0;
      }
    }
  }
  .gome_news_footer {
    .gome_news_footer_title {
      padding-top: 200px;
      padding-bottom: 118px;
      opacity: 1;
      h3 {
        font-size: 40px;
        font-family: Hiragino Sans GB;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        color: #1f2329;
        opacity: 1;
      }
      div {
        width: 60px;
        height: 8px;
        background: linear-gradient(to right, #f6ba4f, #ea8427);
        border-radius: 4px;
        opacity: 1;
        text-align: bottom;
        top: 10px;
        margin: auto;
        position: relative;
      }
    }
    /*
    .gome_news_card {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: auto;
      .gome_news-content-con {
        width: 380px;
        height: 523px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fafafa;
        border-radius: 10px;
        margin-bottom: 50px;
        img {
          width: 380px;
          height: 201px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
        .gome_news-content-con-title {
          display: block;
          position: absolute;
          bottom: 97px;
          left: 0;
          margin-top: 40px;
          margin-left: 30px;
          margin-right: 20px;
          box-sizing: border-box;

          .gome_news-content-con-title-name {
            width: 100%;
            opacity: 1;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #1f2329;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .gome_news-content-con-title-des {
            box-sizing: border-box;
            height: 74px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
            margin-top: 16px;
            margin-bottom: 16px;
          }
          .gome_news-content-con-title-time {
            height: 19px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 26px;
            color: #666666;
          }
        }
        .content-con-more {
          font-size: 18px;
          position: absolute;
          color: #eb8729;
          bottom: 26px;
          left: 30px;
        }
      }
    }*/
  }
}
.gome_news_n {
  width: 100%;
  margin: auto;
  position: relative;

  .right_card {
    padding-top: 0px;
    height: 620px;
    // 隐藏滚动条
    .gome_news-content {
      width: 100%;
      height: 540px;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      .gome_overflow_n {
        display: flex;
        padding: 0 40px;
        width: 100%;
        height: 700px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        justify-content: flex-start;
      }

      &-con {
        width: 380px;
        height: 523px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fafafa;
        border-radius: 10px;
        margin-right: 60px;
        &:hover {
          cursor: pointer;
          background-color: #fff;
          box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
        }
        img {
          width: 380px;
          height: 201px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
        &-more {
          font-size: 18px;
          position: absolute;
          color: #eb8729;
          bottom: 26px;
          left: 30px;
          cursor: pointer;
        }
        .gome_news-content-con-title {
          display: block;
          width: 100%;
          position: absolute;
          bottom: 97px;
          left: 0;
          margin-top: 40px;
          padding: 0 20px;
          box-sizing: border-box;
          &-name {
            width: 100%;
            opacity: 1;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #1f2329;
            white-space: nowrap; /*把文本强制显示在一行*/
            text-overflow: ellipsis; /*超出部分显示...*/
          }
          &-des {
            box-sizing: border-box;
            height: 74px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
            margin-top: 16px;
            margin-bottom: 16px;
          }
          &-time {
            height: 19px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 26px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
