export default {
  companyNatureList: state => state.dictionaryList.COMPANY_NATURE,
  approvalType: state => state.dictionaryList.APPROVAL_TYPE,
  regionList: state => state.regionList,
  bankList: state => state.bankList,
  companyList: state => state.companyList,
  taxpayerFlag: state => state.dictionaryList.TAXPAYER_FLG,
  termOptions: state => state.dictionaryList.VALID_TERM_TYPE,
  privateOptions: state => state.dictionaryList.PUBLIC_PRIVATE,
  entryType: state => state.dictionaryList.ENTRY_TYPE,
  goodOriginList: state => state.dictionaryList.GOOD_ORIGIN,
  yesOrNo: state => state.dictionaryList.YES_OR_NO,
  brandLevel: state => state.dictionaryList.BRAND_LEVEL,

}