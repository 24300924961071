<template>
  <div class="p_container">
    <el-container
      :class="
        [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '8',
          '9',
        ].includes(routerKey)
          ? 'nav-bg-img'
          : ''
      "
    >
      <el-header :class="routerKey === '7-2-1' ? 'el-header1' : 'el-header'">
        <TopNav :routerKey="routerKey" @loginVisible="loginVisible" />
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <Footer />
      </el-footer>
    </el-container>
    <Login
      v-if="loginIsShow"
      :loginIsShow="loginIsShow"
      @loginVisible="loginVisible"
    />
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";
import Login from "@/components/Login.vue";
export default {
  name: "Home",
  data() {
    return {
      routerKey: "0", //路由key值
      loginIsShow: false, //弹出框是否显示
    };
  },
  // 注册子组件
  components: {
    TopNav,
    Footer,
    Login,
  },
  watch: {
    $route(to) {
      if (to.meta.key !== undefined) {
        this.routerKey = to.meta.key;
      }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  methods: {
    // 登陆弹框事件
    loginVisible(status) {
      this.loginIsShow = status;
    },
  },
  // 进入路由前
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.meta.key !== undefined) {
        vm.routerKey = to.meta.key;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.p_container {
  width: 100%;
  background: #fff;
  overflow-x: auto;

  .el-header {
    padding: 0px;
    background: #ffffff;
    position: relative;
    z-index: 2;
    height: 0px !important;
  }
  .el-header1 {
    background: #ffffff;
    position: relative;
    z-index: 2;
    height: 80px !important;
  }
  .el-footer {
    padding: 0;
    overflow-x: auto;
    width: 100%;
    height: auto !important;
    background: #1e222c;
  }

  .el-main {
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }
}

/* 底色为图片的导航 */
.nav-bg-img {
  min-width: 1280px;
  .el-header {
    background-color: transparent;
    /deep/.tab_box {
      .el-menu-item {
        color: #ffffff;
      }

      .el-menu--horizontal > .el-menu-item.is-active {
        color: #ffffff;

        &::after {
          background-color: #ee9118;
          border-radius: 3px;
        }
      }

      .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
      .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
        background-color: transparent;
        color: #ffffff;
      }

      .logo-btn {
        background: transparent;
        color: #d0d1d2;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.2);
        &:hover {
          color: #ffffff;
          border: 1px solid rgba(255, 255, 255, 1);
        }
      }
      .logo-name {
        color: #ffffff;
      }
    }
  }
  .el-main {
    position: relative;
    //top: -80px;
  }
}
</style>
