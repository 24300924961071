/*
 *  指令用来限制一些输入的内容
 */
function numberInput(el, ele, binding, vnode) {
	function handle() {
		let val = ele.value;
		// modifiers为修饰符对象，传入了float浮点型 minus包含负数
		if (binding.modifiers.float || binding.modifiers.minus) {
			if (binding.modifiers.minus) {
				val = val.replace(/[^\-\d.]/g, '') // 清除"数字"和"."以及"-"以外的字符
				val = val.replace(/\.{2,}/g, '.').replace(/-{2,}/g, '-') // 只保留第一个'-'号
				val = val.replace(/^\./g, '0.').replace(/^-\./, '-0.') // 将 '-.' 替换成 '-0.'
			} else {
				val = val.replace(/[^\d.]/g, ''); // 清除"数字"和"."以外的字符
				val = val.replace(/\.{2,}/g, '.'); // 只保留第一个, 清除多余的
				val = val.replace(/^\./g, '0.'); // 第一个字符如果是.号，则补充前缀0
			}
			val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 清除间隔的多个小数点
			if (val > 0 && val.indexOf(".") < 0 && val != "") val = parseFloat(val) +
			''; //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
			if (val < 0 && val != "-" && val.indexOf(".") < 0 && val != "") val = parseFloat(val) +
			''; //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
			if (typeof binding.value !== 'undefined') {
				// 期望保留的最大小数位数
				let pointKeep = 0;
				if (typeof binding.value === 'string' || typeof binding.value === 'number') {
					pointKeep = parseInt(binding.value)
				} else if (typeof binding.value === 'object') {
					// 支持新的小数点保留位配置
					pointKeep = binding.value.decimal
				}
				if (!isNaN(pointKeep)) {
					if (!Number.isInteger(pointKeep) || pointKeep < 0) {
						pointKeep = 0
					}
					if (binding.modifiers.minus) {
						const str = '^(\\-)*(\\d+)\\.(\\d{' + pointKeep + '}).*$'
						const reg = new RegExp(str);
						if (pointKeep === 0) {
							// 不需要小数点
							val = val.replace(reg, '$1$2')
						} else {
							// 通过正则保留小数点后指定的位数
							val = val.replace(reg, '$1$2.$3')
						}
					} else {
						const str = '^(\\d+)\\.(\\d{' + pointKeep + '}).*$';
						const reg = new RegExp(str);
						if (pointKeep === 0) {
							// 不需要小数点
							val = val.replace(reg, '$1')
						} else {
							// 通过正则保留小数点后指定的位数
							val = val.replace(reg, '$1.$2')
						}
					}
				}
			}
		} else {
			val = ele.value.replace(/[^\d]/g, '')
		}
		if (val !== '' && typeof binding.value === 'object') {
			let {
				min,
				max
			} = binding.value;
			min = parseFloat(min);
			max = parseFloat(max);
			if (!isNaN(min)) {
				if (min >= 0) {
					val = val.replace('-', '') // 不能是负数
				}
				if (parseFloat(val) < min) {
					val = min
				}
			}
			if (!isNaN(max)) {
				if (parseFloat(val) > max) {
					val = max
				}
			}
		}
		ele.value = val;
		if (vnode.componentInstance) {
			vnode.componentInstance.$emit('input', ele.value)
		} else {
			vnode.elm.dispatchEvent(new CustomEvent('input', ele.value))
		}
	}
	return handle
}

function nameInput(el, ele, binding, vnode) {
	function handle() {
		let val = ele.value;
		val = val.replace(/[\d]/g, '') // 清除"数字"字符
		// val = val.replace(/[^A-Za-z_\u4e00-\u9fa5]/g, '');
		ele.value = val;
		if (vnode.componentInstance) {
			vnode.componentInstance.$emit('input', ele.value)
		} else {
			vnode.elm.dispatchEvent(new CustomEvent('input', ele.value))
		}
	}
	return handle
}

function priceInput(el, ele, binding, vnode) {
	function handle() {
		let val = ele.value;
		val = val.replace(/[^\-\d.]/g, '') // 清除"数字"和"."以及"-"以外的字符
		val = val.replace(/\.{2,}/g, '.').replace(/-{2,}/g, '-') // 只保留第一个'-'号
		val = val.replace(/^\./g, '0.').replace(/^-\./, '-0.') // 将 '-.' 替换成 '-0.'
		val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); // 清除间隔的多个小数点
		if ((val > 0 && val.indexOf(".") < 0 && val != "") || (val < 0 && val != "-" && val.indexOf(".") < 0 && val !=
				"")) val = parseFloat(val) + ''; //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
		// 整数的位数
		let intKeep = 8;
		if (typeof binding.value === 'string' || typeof binding.value === 'number') intKeep = parseInt(binding.value);
		const str1 = `^(\\d{1,${intKeep}}).*$`;
		const str2 = `^(\\d{1,${intKeep}}).*\\.(\\d{1,2}).*$`;
		const reg1 = new RegExp(str1);
		const reg2 = new RegExp(str2);
		if (val.indexOf(".") < 0) {
			val = val.replace(reg1, '$1');
		} else {
			val = val.replace(reg2, '$1.$2');
		}
		ele.value = val;
		if (vnode.componentInstance) {
			vnode.componentInstance.$emit('input', ele.value)
		} else {
			vnode.elm.dispatchEvent(new CustomEvent('input', ele.value))
		}
	}
	return handle
}

const directive = {
	// input 数字输入框
	numberInput: {
		//<el-input v-model="model" v-number-input />
		//<el-input v-model="model" v-number-input.float />
		//<el-input v-model="model" v-number-input.float="2" />
		//<el-input v-model="model" v-number-input.minus="2" />
		//<el-input v-model="model" v-number-input="{ min: 0 }" />
		// {min: {type: number},max: {type: number},// 最大小数位，仅在float下有效decimal: {type: number}}
		bind(el, binding, vnode) {
			const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
			ele.addEventListener('input', numberInput(el, ele, binding, vnode), false)
		},
	},
	// input 名字输入框
	nameInput: {
		bind(el, binding, vnode) {
			const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
			ele.addEventListener('input', nameInput(el, ele, binding, vnode), false)
		},
	},
	//input 价格输入框（2位小数）（最多8位小数）
	priceInput: {
		//<el-input v-model="model" v-price-input />默认整数部分位8位
		//<el-input v-model="model" v-price-input='8' />
		bind(el, binding, vnode) {
			const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
			ele.addEventListener('input', priceInput(el, ele, binding, vnode), false)
		},
	},
	// 按钮的防重提交
	buttonDoubleClick: {
		bind(el, binding) {
			el.addEventListener('click', () => {
				if (!el.disabled) {
					el.disabled = true
					setTimeout(() => {
						el.disabled = false
					}, binding.value || 3000)
				}
			})
		},
	},
};
export default directive
