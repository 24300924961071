
const filters = {
	//货币符号
	currency: function(value) {
		if (value !== undefined) {
			if (value === '') {
				return '';
			} else {
				value = parseFloat(value);
				if (!isFinite(value) || (!value && value !== 0)) return '';
				var digitsRE = /(\d{3})(?=\d)/g;
				var currency = '$';
				var decimals = 2;
				var stringified = Math.abs(value).toFixed(decimals);
				var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
				var i = _int.length % 3;
				var head = i > 0 ? (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) : '';
				var _float = decimals ? stringified.slice(-1 - decimals) : '';
				var sign = value < 0 ? '-' : '';
				return sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float
			}
		}
	},
	//货币符号
	currency1: function(value) {
		if (value !== undefined) {
			if (value === '') {
				return '';
			} else {
				value = parseFloat(value);
				if (!isFinite(value) || (!value && value !== 0)) return '';
				var digitsRE = /(\d{3})(?=\d)/g;
				var currency = '￥';
				var decimals = 2;
				var stringified = Math.abs(value).toFixed(decimals);
				var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
				var i = _int.length % 3;
				var head = i > 0 ? (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) : '';
				var _float = decimals ? stringified.slice(-1 - decimals) : '';
				var sign = value < 0 ? '-' : '';
				return sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float
			}
		}
	},
	//无货币符号
	currency2: function(value) {
		if (value !== undefined) {
			if (value === '') {
				return '';
			} else {
				value = parseFloat(value);
				if (!isFinite(value) || (!value && value !== 0)) return '';
				var digitsRE = /(\d{3})(?=\d)/g;
				var currency = '';
				var decimals = 2;
				var stringified = Math.abs(value).toFixed(decimals);
				var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
				var i = _int.length % 3;
				var head = i > 0 ? (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) : '';
				var _float = decimals ? stringified.slice(-1 - decimals) : '';
				var sign = value < 0 ? '-' : '';
				return sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float
			}
		}
	},
	//香港货币符号
	currencyHK: function(value) {
		if (value !== undefined) {
			if (value === '') {
				return '';
			} else {
				value = parseFloat(value);
				if (!isFinite(value) || (!value && value !== 0)) return '';
				var digitsRE = /(\d{3})(?=\d)/g;
				var currency = 'HKD';
				var decimals = 2;
				var stringified = Math.abs(value).toFixed(decimals);
				var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
				var i = _int.length % 3;
				var head = i > 0 ? (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) : '';
				var _float = decimals ? stringified.slice(-1 - decimals) : '';
				var sign = value < 0 ? '-' : '';
				return sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float
			}
		}
	},
	//性别展示
	genderType: function(value) {
		if (value !== undefined) {
			if (value === '') {
				return '';
			} else {
				if (value == '1') {
					return '男';
				} else if (value == '2') {
					return '女';
				}
			}
		}
	},
	//有效状态
	enterType: function(value) {
		if (value !== undefined) {
			if (value === '') {
				return '';
			} else {
				if (value == '1') {
					return '有效';
				} else if (value == '2') {
					return '无效';
				}
			}
		}
	},
	//时间格式转化
	dateFormat: function(value) {
		if (value !== undefined) {
			if (value === '') {
				return '';
			} else {
				value = value.split('-').reverse().join("/");
				return new Date(value).format('yyyy-MM-dd');
			}
		}
	},
	//例子
	optionTxt: function(value, obj) {
		// 将传入的obj整成数组，再返回相应的值[主要用于select计算[普通select-val-txt]
		let newObj = {};
		let checkVal = /请选择/;
		if (checkVal.test(value) || value === '') return value;
		else {
			for (let i = 0; i < obj.length; i++) newObj[obj[i].val] = obj[i].txt;
			return newObj[value]
		}
	}
}
export default filters
