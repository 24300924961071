import request from '@/axios'

/**
 * 根据手机号登录
 * @param data
 * @returns {AxiosPromise}
 */
export function loginByPhone(data) {
  return request({
    url: '/api/bff-m/login/loginOrRegisterBySmsCode',
    method: 'post',
    data
  })
}
/**
 * 根据手机号发送短信验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function sendSmsCode(data) {
  return request({
    url: '/api/bff-m/login/sendSmsCode',
    method: 'post',
    data
  })
}

export function getMerchantCode (data) {
  return request({
    url: '/api/bff-m/user/m/getMerchantByPhone',
    method: 'post',
    data
  })
}
/**
 * 刷新token
 * @returns {*}
 */
export const refreshTokenByOldToken = () => request({
  url: `/api/bff-m/login/renewToken`,
  method: 'post',
  Authorization: sessionStorage.getItem('gm_user_token')
})
/**
 * 查询字典列表
 * @returns {AxiosPromise}
 */
export function getAllDictionaryList() {
  return request({
    url: '/api/bff-m/dict/list/all',
    method: 'post'
  })
}

/**
 * 查询省市区列表
 * @returns {AxiosPromise}
 */
export function getRegionList() {
  return request({
    url: '/api/base-m/region/tree',
    method: 'get'
  })
}

/**
 * 查询品牌业态
 * @returns {AxiosPromise}
 */
export function getCaByLevel() {
  return request({
    url: '/api/base-m/ca/getCaByLevel',
    method: 'get'
  })
}
/**
 * 查询品牌信息
 * @returns {AxiosPromise}
 */
export function getBrandLists(params) {
  return request({
    url: '/api/inv-m/brand/list',
    method: 'get',
    params
  })
}
/**
 * 查找品牌
 * @returns {AxiosPromise}
 */
export function findList(params) {
  if (params.zhName === '') delete params.zhName
  if (params.popLevel === '') delete params.popLevel
  return request({
    url: '/api/inv-m/brand/list',
    method: 'get',
    params
  })
}
/**
 * 上传文件
 * @param data
 * @returns {AxiosPromise}
 */
export function uploadFiles(data, onProgress) {
  return request({
    url: '/api/gm-nxcloud-resource/api/nxcloud/res/upload',
    method: 'post',
    data,
    onUploadProgress: onProgress
  })
}
/**
 * 获取文件信息
 * @param data
 * @returns {AxiosPromise}
 */
export function getUploadFile(resId) {
  return request({
    url: `/api/gm-nxcloud-resource/api/nxcloud/res/info?resId=${resId}`,
    method: 'get'
  })
}
/**
 * 获取SAP银行信息
 * @param data
 * @returns {*}
 */
export function getBankList(params) {
  return request({
    url: '/api/inv-m/sapBankInfo/page',
    method: 'get',
    params
  })
}
/**
 * 获取已有公司信息
 * @param data
 * @returns {*}
 */
export function getCompanyList(params) {
  params = {
    ...params,
    judgeMall: 0
  }
  return request({
    url: '/api/inv-m/bmMerchantCompany/page',
    method: 'get',
    params
  })
}
/**
 * 获取商户信息
 * @returns {*}
 */
export function getInfoByUserId() {
  return request({
    url: '/api/inv-m/merchant/getInfoByUserId',
    method: 'get',
  })
}

/**
 * 创建商户信息
 * @param data
 * @returns {*|void}
 */
export function createMerchant(data) {
  return request({
    url: '/api/inv-m/merchant/create',
    method: 'post',
    data
  })
}
/**
 * 添加品牌
 * @param data
 * @returns {*}
 */
export function addBrand(data) {
  return request({
    url: '/api/inv-m/brand/create',
    method: 'POST',
    data
  })
}

/**
 * 入驻申请提交
 * @param data
 * @returns {*|void}
 */
export const setApply = data => {
  return request({
    url: '/api/inv-m/enterApply/create',
    method: 'POST',
    data
  })
} 

/**
 * 更新入驻申请
 * @param data
 * @returns {*|void}
 */
export const updateApply = data => {
  return request({
    url: '/api/inv-m/enterApply/update',
    method: 'POST',
    data
  })
} 

/**
 * 获取入驻申请列表
 * @param data
 * @returns {*|void}
 */
export const getEnterApply = () => {
  return request({
    url: '/api/inv-m/enterApply/page?current=1&pageSize=100&total=0',
    method: 'get'
  })
} 
/**
 * 获取入驻申请信息详情
 * @param id
 * @returns {*}
 */
export const getApplyInfo = id => {
  return request({
    url: `/api/inv-m/enterApply/${id}`,
    method: 'get'
  })
} 

/**
 * 根据ID查询商户公司详情
 * @param id
 * @returns {*}
 */
export const getCompanyInfo = id => {
  return request({
    url: `/api/inv-m/bmMerchantCompany/${id}`,
    method: 'get'
  })
} 

/**
 * 根据业务ID获取审批履历信息
 * @param id
 * @returns {*}
 */
export const getByBusList = id => {
  return request({
    // url: `/api/base-m/approvalRecord/getListByBusId/${id}`,
    url: `/api/base-m/approvalRecord/byWorkFlow?busId=${id}&workFlowKey=approval_process_rzsh`,
    method: 'get'
  })
}
/**
 * 根据ID查询品牌信息
 * @param id
 * @returns {*}
 */
export const getBrandInfo = id => {
  return request({
    url: `/api/inv-m/brand/${id}`,
    method: 'get'
  })
}
/**
 * 根据ID查询品牌logo信息
 * @param id
 * @returns {*}
 */
export const getBrandImageId = id => {
  return request({
    url: `/api/inv-m/brand/${id}`,
    method: 'get'
  })
}

/**
 * 根据公司名称查询公司状态
 */
export const getComStatus = name => {
  return request({
    url: `/api/inv-m/bmMerchantCompany/getComStatus/${name}`,
    method: 'get'
  })
}

/**
 * 下载app
 * http://10.112.6.175:9999/boss/version/appDownload/lastestInfo?type=ANDROID
 */
 export const downLoadApp = () => {
  return request({
    url: `/gateway/version/appDownload/lastestInfo?type=ANDROID`,
    method: 'get'
  })
}
