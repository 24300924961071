import axios from 'axios'
import Cookies from 'js-cookie'
import { encode } from 'js-base64'
import { sha256 } from 'js-sha256'
import router from '@/router'
import store from '@/store'
import {refreshTokenByOldToken} from '@/api'

const serve = axios.create()
let freshing = false
let currentUrl = ''
let requestConfigArr = []

function toLogin () {
  sessionStorage.clear()
  localStorage.clear()
  store.commit('QUIT')
  router.push({
    path: '/index/investmentIn?token=1'
  })
  freshing = false
}

const encryptionTypeConversion = (config, field = 'params', type = true) => {
  let keysArr = []
  let queryString = ''
  if (type) {
    for (let key of Object.keys(config[field])) {
      keysArr.push(key)
    }
  } else {
    for (let key of config[field].keys()) {
      keysArr.push(key)
    }
  }
  keysArr = keysArr.sort()
  for (let key of keysArr) {
    let str = null
    if (type) {
      str = config[field][key]
    } else {
      str = config[field].get(key)
    }
    if (str !== null && typeof str !== 'undefined') {
      queryString += `${key}=${str}&`
    }
  }
  queryString = queryString.slice(0, -1)
  return queryString
}

const encryptionHeaderMessage = config => {
  // 获取加密的字段
  let queryString = ''
  let base64Str = ''
  let signToken = ''
  if (config.headers['Authorization']) {
    signToken = config.headers['Authorization'].slice(7)
  }
  const timestamp = new Date().getTime()
  config.headers['X-NXCloud-Timestamp'] = timestamp
  const nonce = Math.floor(Math.random() * 1000000) + ''
  config.headers['X-NXCloud-Nonce'] = nonce
  const methodStr = config.method.toLowerCase()
  if (methodStr === 'get' || methodStr === 'delete') {
    if (config.params) {
      if (config.params instanceof String) {
        queryString = config.params
      } else {
        const paramsStr = Object.prototype.toString.call(config.params)
        let reqType = true
        if (paramsStr === '[object Object]') {
          reqType = true
        } else if (
          paramsStr === '[object URLSearchParams]' ||
          paramsStr === '[object FormData]'
        ) {
          reqType = false
        }
        queryString = encryptionTypeConversion(config, 'params', reqType)
      }
    }
  } else if ((methodStr === 'post' || methodStr === 'put') && config.data) {
    const paramsStr = Object.prototype.toString.call(config.data)
    if (paramsStr === '[object Object]') {
      base64Str = encode(JSON.stringify(config.data))
    } else if (
      paramsStr === '[object URLSearchParams]' ||
      paramsStr === '[object FormData]'
    ) {
      queryString = encryptionTypeConversion(config, 'data', false)
    }
  }
  config.headers['X-NXCloud-Signature'] = sha256(
    `${queryString + base64Str + timestamp + nonce + signToken}`
  )
}


// 刷新token
const headerConfig = async config => {
  freshing = true
  const configObj = JSON.parse(JSON.stringify(config))
  if (currentUrl === configObj.url) {
    toLogin()
  } else {
    currentUrl = configObj.url
    const res = await refreshTokenByOldToken()
    if (res.data.code === 0) {
      const { sessionToken, userToken } = res.data.data
      sessionStorage.setItem('gm_user_token', `Bearer ${userToken}`)
      sessionStorage.setItem('user_token', `Bearer ${sessionToken}`)
      Object.assign(configObj.headers, {
        Authorization: `Bearer ${sessionToken}`,
        withCredentials: true
      })
      requestConfigArr.length > 0 && requestConfigArr.map(fu => fu())
      requestConfigArr.length = 0
      freshing = false
      return configObj
    } else {
      toLogin()
    }
  }
  return false
}


serve.interceptors.request.use(config => {
  if (config.url.indexOf('loginBySmsCode') === -1
      && config.url.indexOf('loginOrRegisterBySmsCode') === -1
      && config.url.indexOf('getMerchantByPhone') === -1
      && config.url.indexOf('appDownload') === -1
      && config.url.indexOf('sendSmsCode') === -1) {
    Cookies.set('gm_token', sessionStorage.getItem('user_token'))
    Object.assign(config.headers, {
      Authorization: config.Authorization || sessionStorage.getItem('user_token'),
      'X-NXCloud-Owner': sessionStorage.getItem('merchant_code'),
      'Content-Type': 'application/json;charset=UTF-8',
      withCredentials: true
    })
  } else {
    Object.assign(config.headers, {
      Authorization: sessionStorage.getItem('user_token') || '',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    })
    if (config.data) {
      let formData = new FormData()
      Object.keys(config.data).forEach(key => {
        formData.append(key, config.data[key])
      })
      config.data = formData
    }
    
  }
  if (config.url.indexOf('upload') !== -1) {
    if (config.data) {
      let formData = new FormData()
      Object.keys(config.data).forEach(key => {
        formData.append(key, config.data[key])
      })
      config.data = formData
    }
  }
  if (config.url.indexOf('ivt-m') === -1) {
    encryptionHeaderMessage(config)
  }
  
  if (process.env.VUE_APP_MODE === 'production') {
    config.url = config.url.replace(/^\/api/, '/api-match')
  }
  return config
})

// 添加响应拦截器
serve.interceptors.response.use(
  async response => {
    if (response.status === 200 && response.data.code !== 0) {
      if (response.data.code === 10001) {
        const configObj = await headerConfig(response.config)
        if (configObj) {
          return serve.request(configObj)
        } else {
          return response
        }
      } else if (response.data.code === 10002) {
        toLogin()
      } else {
        return response
      }
    } else {
      return response
    }
  },
  async error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      if (!freshing) {
        const configObj = await headerConfig(error.config)
        if (configObj) {
          const res = serve.request(configObj)
          return res
        }
      } else {
        return new Promise(resolve => {
          requestConfigArr.push(() => {
            error.config.headers.Authorization = sessionStorage.getItem(
              'user_token'
            )
            error.config.headers.withCredentials = true
            resolve(serve.request(error.config))
          })
        })
      }
    }
    return Promise.reject(error)
  })

export default serve
