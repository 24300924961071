import {
  SET_DICTIONARY_LIST,
  SET_REGION_LIST,
  SET_BANK_LIST,
  SET_COMPANY_LIST,
  SET_USERNAME,
  SET_LIST_LOADING,
  QUIT
} from './mutation-types'
import {
  getAllDictionaryList,
  getRegionList,
  getBankList,
  getCompanyList,
  loginByPhone,
  getMerchantCode
} from '@/api'

export default {
  getAllDictionaryList({ commit }) {
    return getAllDictionaryList().then(res => {
      commit(SET_DICTIONARY_LIST, res.data.data.data)
    })
  },
  getRegionList({ commit }) {
    return getRegionList().then(res => {
      commit(SET_REGION_LIST, res.data.data)
    })
  },
  getBankList({ commit }, payload) {
    commit(SET_LIST_LOADING, true)
    return getBankList(payload).then(res => {
      const bank = {}
      commit(SET_LIST_LOADING, false)
      const data = res.data.data
      bank.list = data.records
      bank.current = data.current
      bank.total = data.total
      commit(SET_BANK_LIST, bank)
    })
  },
  getCompanyList({ commit }, payload) {
    commit(SET_LIST_LOADING, true)
    return getCompanyList(payload).then(res => {
      const company  = {}
      commit(SET_LIST_LOADING, false)
      const data = res.data.data
      company.list = data.records
      company.current = data.current
      company.total = data.total
      commit(SET_COMPANY_LIST, company)
    })
  },

	// 手机号登录
	loginByPhone({commit},params) {
		return new Promise((resolve, reject) => {
			loginByPhone(params).then(res => {
				if (res.data.code === 0) {
					sessionStorage.setItem('user_token', `Bearer ${res.data.data.sessionToken}`)
					sessionStorage.setItem('gm_user_token', `Bearer ${res.data.data.userToken}`)
					sessionStorage.setItem('user_name', res.data.data.merchantUser.realname || 'admin')
					sessionStorage.setItem('user_phone', res.data.data.merchantUser.phoneNum || 'admin')
          sessionStorage.setItem('user_id', res.data.data.merchantUser.id)
					let userName = res.data.data.merchantUser.realname || 'admin'
					commit(SET_USERNAME, userName)
				}
				resolve(res)
			}).catch(error => {
				reject(error)
			})
		})
	},
	// 退出
	quit({commit}){
		sessionStorage.removeItem('user_token')
		sessionStorage.removeItem('user_name')
		sessionStorage.removeItem('user_phone')
		sessionStorage.removeItem('merchant_code')
    commit('SET_UPDATE_STATUS', 0)
		commit(QUIT)
	},
  getMerchantCode({ commit }, params) {
    console.log(commit)
    return getMerchantCode(params).then(res => {
      if (!res.data.data.merchantDetail) return
      sessionStorage.setItem('merchant_code', res.data.data.merchantDetail.code)
    })
  }
}