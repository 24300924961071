<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>


<style lang="scss">
  @import '~@/assets/icon/iconfont.css';
  @import '@/assets/scss/main.scss';
  @import '@/assets/scss/merchant.scss';

	.icon {
		width: 1em; 
		height: 1em;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
