<template>
  <div class="footer_bottom">
    <div class="footer_bottom-top">
      <img
        class="img1"
        src="@/assets/img/logo2.png"
        alt=""
      />
      <div class="footer_bottom-top-con">
        <h3>站内导航</h3>
        <span @click="toGome">国美家</span>
        <span @click="toBusiness">商业模式</span>
        <span @click="toProject">项目介绍</span>
        <span @click="toMallGuide">招商入驻</span>
        <span @click="toNews">新闻中心</span>
        <span @click="toContactWay">加入我们</span>
        <span @click="toContactWay">联系方式</span>
        <span @click="toDownloadApp">手机APP</span>
        <span @click="handleClick">隐私协议</span>
      </div>
      <div class="footer_bottom-top-con">
        <h3>快速链接</h3>
        <span @click="goHolding">国美控股</span>
        <span @click="goGome">国美零售</span>
        <span @click="goGome">真快乐</span>
        <span @click="goGome">国美电器</span>
      </div>
      <div class="footer_bottom-top-con end_column">
        <h3>社区</h3>
        <span>微博</span>
        <span>微信</span>
        <span>公众号</span>
      </div>
    </div>
    <div class="footer_bottom-bottom">
      <span @click="handleJump">琼ICP备2021001659号-1</span> Copyright © 2022
      国美家
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleJump() {
      window.open("https://www.miit.gov.cn/");
    },
    handleClick() {
      this.$router.push("/userAgreement");
    },
    toGome() {
      this.$router.push("/index/gome");
    },
    toBusiness() {
      this.$router.push("/index/business");
    },
    toProject() {
      this.$router.push("/index/project");
    },
    toMallGuide() {
      this.$router.push("/index/mallGuide");
    },
    toNews() {
      this.$router.push("/index/news");
    },
    toContactWay() {
      this.$router.push("/index/contactWay");
    },
    toDownloadApp() {
      this.$router.push("/index/downloadApp");
    },
    goHolding() {
      window.open("https://www.gomeholdings.com/");
    },
    goGome() {
      window.open("https://www.gome.com.cn/");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer_bottom {
  width: 1200px;
  margin: auto;
  margin-top: 34px;
  background: #1e222c;

  &-top {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    img {
      width: 91px;
      height: auto;
      object-fit: contain;
    }

    &-con {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      h3 {
        font-size: 24px;
        font-family: Hiragino Sans GB;
        font-weight: 600;
        line-height: 37px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 30px;
        cursor: pointer;
      }

      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 37px;
        color: #595f6e;
        opacity: 1;
        cursor: pointer;
        &:hover {
          color: white;
          font-weight: 700;
        }
      }
    }
    /*最后一列设置有外边距为0 */
    .end_column {
      margin-right: 0;
    }
  }

  &-bottom {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 25px;
    color: #595f6e;
    opacity: 1;
    margin: 92px 0 18px;
    text-align: center;

    span {
      cursor: pointer;
    }
  }
}
</style>
